@use "variables" as *;
@use "fonts";
@use "header";
@use "footer";
@use "accessiblity";
@use "banners";
@use "bootstrapoverride";

html{
  line-height: 1.15;
  font-size: 10px;
  scroll-behavior: smooth;
}
body{
  scroll-behavior: smooth;
}
.container {
  .row {
    padding: 40px 0px;
  }
}
img{
  height: auto;
}
.button,
.btn {
  background-color: transparent;
  color: $white;
  padding: 10px 25px;
  font-family: $Rajdhani; 
  font-weight: bold;
  border: 1px solid $teal;
  //font-weight: bold;
  text-align: center;
  display: inline-block;
  border-radius: 10px;
  line-height: 1.5;
  font-size: 1.8rem;
  -webkit-transition: all 0.75s ease-in-out;
  -moz-transition: all 0.75s ease-in-out;
  -o-transition: all 0.75s ease-in-out;
  transition: all 0.75s ease-in-out;

  &:after{
    content: " ";
    display: inline-block;
    position: relative;
    background-image: url('../img/buttonarrow.png');
    width: 20px;
    height: 13px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 20px;
    -webkit-transition: all 0.75s ease-in-out;
    -moz-transition: all 0.75s ease-in-out;
    -o-transition: all 0.75s ease-in-out;
    transition: all 0.75s ease-in-out;
  }
}
.button:hover,
.button:focus,
.btn:hover,
.btn:focus {
  text-decoration: none;
  background-color: $teal!important;
  border: 1px solid $white;
  color: white;
  -webkit-transition: all 0.75s ease-in-out;
  -moz-transition: all 0.75s ease-in-out;
  -o-transition: all 0.75s ease-in-out;
  transition: all 0.75s ease-in-out;
}

.gform_button, .gform_wrapper.gravity-theme #field_submit input, .gform_wrapper.gravity-theme .gform_footer input{
  border-radius: 8px!important;
}

.wp-block-button.is-style-default-button{
  .wp-element-button{
    background-color: transparent;
    color: $white;
    padding: 10px 25px;
    font-family: $Rajdhani; 
    font-weight: bold;
    border: 1px solid $teal;
    //font-weight: bold;
    text-align: center;
    display: inline-block;
    border-radius: 10px;
    font-size: 1.8rem;
    line-height: 1.5;
    -webkit-transition: all 0.75s ease-in-out;
    -moz-transition: all 0.75s ease-in-out;
    -o-transition: all 0.75s ease-in-out;
    transition: all 0.75s ease-in-out;
    &:after{
      content: " ";
      display: inline-block;
      position: relative;
      background-image: url('../img/buttonarrow.png');
      width: 20px;
      height: 13px;
      background-repeat: no-repeat;
      background-size: cover;
      margin-left: 20px;
      -webkit-transition: all 0.75s ease-in-out;
      -moz-transition: all 0.75s ease-in-out;
      -o-transition: all 0.75s ease-in-out;
      transition: all 0.75s ease-in-out;
    }
  }

  .wp-element-button:hover,
  .wp-element-button:focus,
  .wp-element-button:hover,
  .wp-element-button:focus{
    text-decoration: none;
    background-color: $teal!important;
    border: 1px solid $white;
    color: white;
    -webkit-transition: all 0.75s ease-in-out;
    -moz-transition: all 0.75s ease-in-out;
    -o-transition: all 0.75s ease-in-out;
    transition: all 0.75s ease-in-out;
  }
}


.wp-block-button.is-style-default-button-black{
  .wp-element-button{
    background-color: transparent;
    color: $black;
    padding: 10px 25px;
    font-family: $Rajdhani; 
    font-weight: bold;
    border: 1px solid $teal;
    //font-weight: bold;
    text-align: center;
    display: inline-block;
    border-radius: 10px;
    font-size: 1.8rem;
    line-height: 1.5;
    -webkit-transition: all 0.75s ease-in-out;
    -moz-transition: all 0.75s ease-in-out;
    -o-transition: all 0.75s ease-in-out;
    transition: all 0.75s ease-in-out;
    &:after{
      content: " ";
      display: inline-block;
      position: relative;
      background-image: url('../img/buttonarrow.png');
      width: 20px;
      height: 13px;
      background-repeat: no-repeat;
      background-size: cover;
      margin-left: 20px;
      -webkit-transition: all 0.75s ease-in-out;
      -moz-transition: all 0.75s ease-in-out;
      -o-transition: all 0.75s ease-in-out;
      transition: all 0.75s ease-in-out;
      -webkit-filter: invert(1);
      filter: invert(1);
    }
  }
  .wp-element-button:hover,
  .wp-element-button:focus,
  .wp-element-button:hover,
  .wp-element-button:focus{
    text-decoration: none;
    background-color: $teal;
    border: 1px solid $white;
    color: white;
    -webkit-transition: all 0.75s ease-in-out;
    -moz-transition: all 0.75s ease-in-out;
    -o-transition: all 0.75s ease-in-out;
    transition: all 0.75s ease-in-out;
    &:after{
      -webkit-filter: invert(0);
      filter: invert(0);
    }
  }
}
.wp-block-button.is-style-teal-circle-button{
  .wp-element-button{
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    //max-width: 150px;
    height: 80px;
    padding: 30px 0px;
    color: white!important;
    background: transparent;
    font-size: 1.8rem;
    @media only screen and (max-width: 480px) {
      margin-bottom: 30px;
      margin-left: 20px;
    }
    *{
      align-self: center;
    }
    img{
      margin-left: 20px;
    }
    &::before{
      transform: translateX(0px);
      transition: transform 500ms ease-in-out;
      z-index: 1;
      content: '';
      display: block;
      position: absolute;
      height: 80px;
      width: 80px;
      border: 2px solid $teal;
      //left: -20px;
      right: 70%;
      border-radius: 300px;
      align-self: center;
      transition: right 0.3s linear;
    }
    &:after{
      content: " ";
      display: inline-block;
      position: relative;
      background-image: url('../img/buttonarrow.png');
      width: 20px;
      height: 13px;
      background-repeat: no-repeat;
      background-size: cover;
      margin-left: 20px;
      -webkit-transition: all 0.75s ease-in-out;
      -moz-transition: all 0.75s ease-in-out;
      -o-transition: all 0.75s ease-in-out;
      transition: all 0.75s ease-in-out;
      align-self: center;
    }
  }

  .wp-element-button:hover,
  .wp-element-button:focus,
  .wp-element-button:hover,
  .wp-element-button:focus{
    &::before{
      //transform: translateX(95px);
      //transition: transform 500ms ease-in-out;
      transition: right 0.3s linear;
      right: -30px;
    }
  }
}

.allportfolio{
  padding: 15px 25px;
  color: $black;
  font-family: $Rajdhani;
  font-weight: bold;
  border: 1px solid $teal;
  text-align: center;
  display: inline-block;
  border-radius: 10px;
  line-height: 1.5;
  font-size: 1.8rem;
  transition: all 0.75s ease-in-out;
  border-radius: 36px;
  margin-bottom: 80px;
  &:hover{
    background-color: $teal;
    color: white;
  }
}
body{
  a:hover{
    text-decoration: none;
  }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus{
    color: initial;
    text-decoration: none;
  }
}

.row {
  max-width: 1140px;
  display: flex;
  margin: auto !important;
  flex-wrap: wrap;
  flex: 0 0 100%;
}

.black {
  color: $black;
}

#content ul {
  padding-left: 0px;
  margin-bottom: 20px;
}

.gform_confirmation_message{
  font-size: 1.8rem;
  padding: 40px 0px;
  color: white;
}
.name_first{
  padding-right: 0px!important;
}
.gform_wrapper.gravity-theme .gfield input, .gform_wrapper.gravity-theme .gfield textarea, .gform_wrapper.gravity-theme .gfield select{
  border: 2.5px solid #CCCCCC;
  border-radius: 15px;
  padding: 30px 25px!important;
  color: white;
  font-family: $Rajdhani;
  font-size: 1.8rem!important;
  background-color: black;
  &::placeholder{
    color: white;
    opacity: 0.5;
  }
}
.gform_wrapper.gravity-theme .gfield select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance:none;
  cursor: pointer;
  background-image: url('../img/tealtriangle.png');
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 30px;
}
.gform_footer{
  max-width: 250px;
  position: absolute;
  bottom: -10px;
  z-index: 9999;
  right: 0;
  @media only screen and (max-width: 768px) {
    position: relative;
    bottom: 0;
    right: 0;
  }
}
form, .gform-body{
  position: relative;
}
.gform_button{
  cursor: pointer;
}
#SubpageOptions {
  .row {
    padding: 60px 0px;
    flex: 0 0 100%;
    justify-content: center;
    .discover {
      flex: 0 0 100%;
      max-width: 100%;
      align-self: center;
      * {
        text-align: center;
      }
      h3 {
        font-family: $title;
        font-weight: 600;
        font-size: 2.8rem;
        line-height: 42px;
        color: $black;
        text-align: center;
      }
    }
    .auditoption {
      flex: 0 0 100%;
      max-width: calc(33% - 45px);
      background: #ffffff;
      box-shadow: 0px 8px 40px rgba(195, 203, 241, 0.34);
      border-radius: 8px;
      padding: 30px 15px;
      text-align: center;
      display: inline-flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 15px;

      h3 {
        color: $teal;
        width: 100%;
        text-align: center;
        font-size: 2.1rem;
        margin-bottom: 20px;
      }
      p {
        text-align: center;
      }
      .button {
        margin: auto;
      }
    }
    .auditoption:hover,
    .auditoption:focus,
    .auditoption.selected {
      background-color: $black;
      p,
      h4,
      h3 {
        color: white;
      }
    }
  }
}

#formbar {
  background-color: $black;
  position: relative;
  overflow: hidden;

    p {
      color: white;
      position: relative;
      z-index: 101;
    }
    h4 {
      color: white;
      position: relative;
      z-index: 101;
    }
    .ContactBlueBlob {
      position: absolute;
      top: -100px;
      right: -210px;
      z-index: 96;
      width: 844px;
      height: 477px;
      max-width: 200%;
      transform: rotate(-8deg);
    }
    .formcontainer {
      position: relative;
      z-index: 98;
      background-color: transparent;
      padding: 10px 0px;
      display: flex;
      flex: 0 0 100%;
      max-width: 100%;
      flex-wrap: wrap;

      label {
        flex: 0 0 100%;
        max-width: calc(33% - 60px);
      }
      .labelname {
        display: none;
      }
      input[type="text"],
      input[type="email"] {
        margin-bottom: 10px;
        background: #f7f6fb;
        border: 1px solid #d9d6d6;
        box-sizing: border-box;
        width: 100%;
        font-size: 1.6rem;
        color: $black;
        font-family: $reg;
        padding: 12px 20px;
        min-height: 48px;
        height: 50px;
      }
      p {
        margin-bottom: 0px;
        display: flex;
        flex: 0 0 100%;
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .button {
        border: 0px;
        text-align: center;
        cursor: pointer;
        height: 50px;
      }
      .button:hover {
        background-color: white;
        color: $black;
      }
    }

}

#AODACompliance {

    h4 {
      width: 100%;
      text-align: center;
      font-family: $title;
      color: $black;
      font-weight: 600;
      font-size: 2.8rem;
    }
    strong {
      display: block;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 30px;
      color: $black;
    }
    p {
      font-family: $reg;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 30px;
      color: $black;
    }
  }
  .threesections {
    padding-top: 80px;
    padding-bottom: 125px;
    display: flex;
    justify-content: space-between;
    max-width: 1320px;
    flex: 0 0 100%;
    .left {
      width: 400px;
      text-align: left;
    }
    .middle {
      max-width: 386px;
    }
    .right {
      width: 400px;
      text-align: left;
    }

}

#AccessibilityAnalysis {
  background-color: $black;
  position: relative;
  overflow: hidden;


      h5 {
        color: white;
        font-weight: 600;
        font-size: 2.8rem;
        margin-bottom: 60px;
      }
      p {
        font-family: $reg;
        color: white;
        margin-bottom: 40px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;
      }
      p:last-child {
        margin-bottom: 0px;
      }
    .Womenlaptop {
      position: relative;
      z-index: 99;
      bottom: 0px;
      right: -60px;
      width: 363px;
      height: 374px;
    }
    #formcontainer form{
      position: relative;
      z-index: 98;
      background-color: white;
      max-width: 455px;
      float: right;
      padding: 60px 55px;
      padding-bottom: 25px;

      .labelname {
        display: none;
      }
      input[type="text"],
      input[type="email"] {
        margin-bottom: 10px;
        background: #f7f6fb;
        border: 1px solid #d9d6d6;
        box-sizing: border-box;
        width: 100%;
        font-size: 1.6rem;
        color: $black;
        font-family: $reg;
        padding: 12px 20px;
        min-height: 48px;
      }
      textarea {
        margin-bottom: 30px;
        background: #f7f6fb;
        border: 1px solid #d9d6d6;
        box-sizing: border-box;
        width: 100%;
        height: 92px;
        font-size: 1.6rem;
        color: $black;
        font-family: $reg;
        padding: 12px 20px;
      }
      p {
        margin-bottom: 0px;
      }
      .button {
        border: 0px;
        width: 100%;
        text-align: center;
        cursor: pointer;
      }
    }
}

.subpagenewspage {
  padding: 80px 0px;
  width: 100%;

  nav {
    width: 100%;
    margin: 40px 0px;

    .nav-links {
      display: flex;
      flex: 0 0 100%;
      max-width: 100%;
      justify-content: space-between;

      a {
        background-color: black;
        color: #FFFFFF;
        padding: 10px 25px;
        font-family: "Rajdhani", "BEBAS", sans-serif;
        font-weight: bold;
        border: 1px solid #0CE6DC;
        text-align: center;
        display: inline-block;
        border-radius: 10px;
        font-size: 1.8rem;
        line-height: 1.5;
        transition: all 0.75s ease-in-out;
        &:hover{
          text-decoration: none;
          background-color: #0CE6DC !important;
          border: 1px solid #FFFFFF;
          color: white;
          transition: all 0.75s ease-in-out;
        }
      }
    }
  }
  article {
    width: 100%;
  }
  .card-body {
    padding: 0px;
  }
  ul {
    padding-left: 20px !important;
    margin-bottom: 40px !important;
    li {
      margin-bottom: 10px;
    }
  }
  a {
    color: $teal;
    text-decoration: underline;
  }
}
.site-content ul:not(.slick-dots), 
.site-content ul:not(#TabNav){
  list-style: none;
  li{
    font-size: 1.8rem;
    font-family: $reg;
    color: $black;
    line-height: 1.5;
  }
  li::before{
    content: '';
    background-image: url('/img/tealcircle.png');
    width: 15px;
    height: 15px;
    background-size: contain;
    display: inline-block;
    margin-right: 10px;
    
  }
}
.site-content ul.slick-dots{
  li::before{
    display: none!important;
  }
}





.singleservices{
  max-width: 1270px;
  padding: 15px;
  margin: auto;
  .button{
    background-color: $black!important;
  }
  .button:hover{
    background-color: $teal!important;
  }
}

.blog,
.single, .archive {
  .navigation  {
    margin-bottom: 60px;
    ul {
      display: flex;
      flex: 0 0 100%;
      max-width: 100%;
      justify-content: center;
      list-style: none;

      li {
        a:not(.button) {
          color: $black;
          margin: 0px 10px;
          border-bottom: 2px solid $teal;
          padding: 8px 10px;
          display: block;
          text-align: center;
          font-size: 20px;
          text-decoration: none;
        }
        a:not(.button):hover {
          color: $teal;
        }
        .button {
          margin: 0px 10px;
        }
      }
      .active{
        color: $teal;
        font-weight: bold;
      }
    }
  }
}
#pagination{
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  flex: 0 0 100%;
  max-width: 100%;

      button:hover {
        color: $teal;
      }
      button{
        color: #000000;
        margin: 0px 10px;
        border: 0px;
        border-bottom: 2px solid $teal;
        padding: 8px 10px;
        display: block;
        text-align: center;
        font-size: 20px;
        text-decoration: none;
        background-color: white;

      }
      .button {
        margin: 0px 10px;
      }
      span{
        color: #000000;
        margin: 0px 10px;
        border: 0px;
        padding: 8px 10px;
        display: block;
        text-align: center;
        font-size: 20px;
        text-decoration: none;
        background-color: white;
      }


    .page-buttonnext{
      background-color: $black;
      color: $white;
      padding: 10px 25px;
      font-family: $Rajdhani; 
      font-weight: bold;
      border: 1px solid $teal;
      //font-weight: bold;
      text-align: center;
      display: inline-block;
      border-radius: 10px;
      line-height: 1.5;
      font-size: 1.8rem;
      -webkit-transition: all 0.75s ease-in-out;
      -moz-transition: all 0.75s ease-in-out;
      -o-transition: all 0.75s ease-in-out;
      transition: all 0.75s ease-in-out;
    
      &:after{
        content: " ";
        display: inline-block;
        position: relative;
        background-image: url('../img/buttonarrow.png');
        width: 20px;
        height: 13px;
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: 20px;
        -webkit-transition: all 0.75s ease-in-out;
        -moz-transition: all 0.75s ease-in-out;
        -o-transition: all 0.75s ease-in-out;
        transition: all 0.75s ease-in-out;
      }

      &:hover{
        background-color: $teal;
        color: $white;
      }
    }

    .page-buttonprev{
      background-color: $black;
      color: $white;
      padding: 10px 25px;
      font-family: $Rajdhani; 
      font-weight: bold;
      border: 1px solid $teal;
      //font-weight: bold;
      text-align: center;
      display: inline-block;
      border-radius: 10px;
      line-height: 1.5;
      font-size: 1.8rem;
      -webkit-transition: all 0.75s ease-in-out;
      -moz-transition: all 0.75s ease-in-out;
      -o-transition: all 0.75s ease-in-out;
      transition: all 0.75s ease-in-out;
    
      &:before{
        content: " ";
        display: inline-block;
        position: relative;
        background-image: url('../img/buttonarrow.png');
        transform: rotate(180deg);
        width: 20px;
        height: 13px;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 20px;
        -webkit-transition: all 0.75s ease-in-out;
        -moz-transition: all 0.75s ease-in-out;
        -o-transition: all 0.75s ease-in-out;
        transition: all 0.75s ease-in-out;
      }

      &:hover{
        background-color: $teal;
        color: $white;
      }
    }
    .current{
      color: $black;
      font-weight: bold;
    }
  

    button:hover {
      color: $teal;
    }
}
.pagination{
  margin-bottom: 60px;
    ul {
      display: flex;
      flex: 0 0 100%;
      max-width: 100%;
      justify-content: center;
      list-style: none;

      li {
        a:not(.button):hover {
          color: $teal;
        }
        a{
          color: #000000;
          margin: 0px 10px;
          border-bottom: 2px solid $teal;
          padding: 8px 10px;
          display: block;
          text-align: center;
          font-size: 20px;
          text-decoration: none;
        }
        .button {
          margin: 0px 10px;
        }
      }
      .active{
        color: $teal;
        font-weight: bold;
      }
    }
  li::before{
    display: none!important;
    
  }
  a:hover {
    color: $teal;
  }
}

.BlockAccordion {
  padding: 20px 0px 50px;

  @media only screen and (max-width: 1240px) {
    padding: 15px 0px 40px;
  }

  .row {
    max-width: 1140px;
    padding-left: 0px;
    padding-right: 0px;
    @media only screen and (max-width: 1240px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  
  }


  div.panel {
      display: none;
      width: 100%;

      p, span, div{
        font-size: 1.8rem;
        font-family: $reg;
        color: $black;
        line-height: 33px;
        margin-bottom: 2rem;
      }

  }

  div.panel.show {
      display: block !important;
  }


  .accordion {
    background-color: transparent;
    font-family: $title;
    font-weight: bold;
    color: $black;
    cursor: pointer;
    width: 100%;
    border: none;
    border-bottom: 1px solid $black;
    text-align: left;
    outline: none;
    font-size: 2.2rem;
    transition: 0.4s;
    padding: 15px 0px;
    line-height: 40px;
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
    position: relative;
    padding-left: 25px;
    img{
      align-self: center;
    }
  }

  
  .active,
  .accordion:hover {
    background-color: transparent;
  }

  .accordion:before {
    content: " " !important;
    background-color: $teal !important;
    display: inline-block;
    height: 11px;
    width: 11px;
    border-radius: 100%;
    left: 0px;
    position: absolute;
    top: 30px;
  }

  .active img {
    transform: rotate(180deg);
  }


  .panel {
    padding: 0 18px;
    background-color: transparent;
    color: $black;
    //max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
}


.panel a {
  color: inherit;
  color: $teal;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}

.panel a:hover {
  color: inherit;
  text-decoration: none;
  border-bottom: 2px solid $teal;
}

.panel ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 1.6rem;
  position: relative;
  li{
    position: relative;
  }
}

.panel ul li:before {
  content: "";
  //background-image: url(img/bulletpoint.png);
  //background-repeat: no-repeat;
  display: inline-block;
  width: 11px;
  height: 5px;
  background-color: $teal;
  background-size: cover;
  align-self: center;
  position: absolute;
  left: -25px;
  top: 15px;
}


.wpcf7 form .wpcf7-response-output {
  color: white;
  font-size: 1.6rem;
}
#SubBanner {
  .wpcf7 form .wpcf7-response-output {
    margin: 0px;
    color: black;
    font-size: 1.6rem;
  }
}
.auditoption p {
  width: 100%;
}
@media only screen and (max-width: 1400px) {
  #AODACompliance .threesections {
    padding: 60px 15px 60px 15px;
  }

  #AODACompliance .threesections {
    padding-top: 20px;
    padding-bottom: 80px;
    max-width: 100%;
    flex: 0 0 100%;
    flex-wrap: wrap;
    padding-left: 15px;
    padding-right: 15px;
  }
  #AODACompliance .threesections .right,
  #AODACompliance .threesections .left {
    width: 49%;
    text-align: left;
    margin-top: 15px;
  }
  #AODACompliance .threesections .middle {
    max-width: 100%;
    order: -1;
    width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 1240px) {
  #AccessibilityAnalysis .threesections {
    padding: 50px 15px;
  }
  #MainBanner .leftside ul.checkmark li:before {
    padding-left: 15px;
  }
}
@media only screen and (max-width: 1140px) {
  #AuditOptions .discover {
    width: 100%;
    text-align: center;
    align-self: center;
    margin-bottom: 40px;
  }

}

@media only screen and (max-width: 1140px) {

  h1,
  .h1 {
    font-size: 6rem!important;
  }
  h2,
  .h2 {
    font-size: 4.2rem!important;
  }
  h3,
  .h3 {
    font-size: 3.2rem!important;
  }
  h4,
  .h4 {
    font-size: 2.8rem;
  }
  h5,
  .h5 {
    font-size: 2.2rem;
  }
  h6,
  .h6 {
    font-size: 1.8rem;
  }
  p {
    font-size: 1.6rem;
  }
  a {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 991px) {
  #SubpageOptions .row .auditoption {
    flex: 0 0 100%;
    max-width: calc(50% - 45px);
  }
  #formbar .row .formcontainer label,
  #formbar .row .formcontainer .button {
    flex: 0 0 100%;
    max-width: calc(50% - 10px);
  }
  #SubBanner {
    height: auto;
    .row {
      position: relative;
      height: auto;
      padding: 60px 15px;
      .leftside {
        max-width: 100%;
        flex: 0 0 100%;
        align-self: center;
        margin-bottom: 40px;
      }
      .rightside {
        max-width: 100%;
        flex: 0 0 100%;
        align-self: center;
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  h1,
  .h1 {
    font-size: 4.8rem!important;
  }
  h2,
  .h2 {
    font-size: 3.8rem!important;
  }
  .col-md-12 {
    /* width: 100%; */
    flex: 0 0 100%;
  }
  footer .row .footer-logo {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 768px) {
  #MainBanner .orangeblob {
    position: absolute;
    top: -5px;
    right: -50px;
  }
  #AccessibilityAnalysis .threesections .right .Womenlaptop {
    display: none;
  }
  label {
    width: 100%;
  }
  #AccessibilityAnalysis .threesections .right #formcontainer {
    max-width: 100%;
  }
  #AccessibilityAnalysis .threesections .left h5 {
    margin-bottom: 40px;
  }
  #AccessibilityAnalysis .threesections .left p {
    margin-bottom: 20px;
  }
  #AODACompliance .threesections .right,
  #AODACompliance .threesections .left {
    width: 100%;
    text-align: left;
    margin-top: 15px;
  }
  .logo-text {
    display: inline-flex;
    flex: 0 0 100%;
    align-content: center;
    align-items: center;
  }
  .col-sm-12 {
    /* width: 100%; */
    flex: 0 0 100%;
  }
  .displaymobile {
    display: block;
  }
  h1,
  .h1 {
    font-size: 4.2rem!important;
  }
  h2,
  .h2 {
    font-size: 3.2rem!important;
  }
  h3,
  .h3 {
    font-size: 2.8rem!important;
  }
  h4,
  .h4 {
    font-size: 2.6rem!important;
  }
  h5,
  .h5 {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 660px) {
  #SubpageOptions .row .auditoption {
    flex: 0 0 100%;
    max-width: 100%;
  }
  footer .row .footermenu {
    align-self: center;
    max-width: 50%;
    flex: 0 0 100%;
    margin-bottom: 40px;
    position: relative;
    z-index: 99;
  }
  footer .row .footer-logo {
    align-self: center;
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 40px;
    position: relative;
    z-index: 99;
  }
  footer .row .GooderMarketing {
    align-self: center;
    max-width: 100%;
    flex: 0 0 100%;
    position: relative;
    z-index: 99;
  }
  header #topbar a img {
    margin-right: 5px;
    align-self: center;
  }
  header #topbar a {
    color: white;
    display: inline-flex;
  }
  header #topbar .headerphone {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 576px) {
  #formbar .formcontainer label,
  #formbar .formcontainer .button {
    flex: 0 0 100%;
    max-width: 100%;
  }

  header #bottombar #logo .full-text {
    margin-left: 10px;
    font-size: 1.6rem;
    font-weight: 600;
  }
  .col-xsm-12 {
    /* width: 100%; */
    flex: 0 0 100%;
  }

  h1,
  .h1 {
    font-size: 3.2rem!important;
  }
  h2,
  .h2 {
    font-size: 3rem!important;
  }
  h3,
  .h3 {
    font-size: 2.6rem!important;
  }
  h4,
  .h4 {
    font-size: 2.4rem!important;
  }
  h5,
  .h5 {
    font-size: 1.8rem;
  }
  h6,
  .h6 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1.6rem;
  }
  a {
    font-size: 1.6rem;
  }

  header #topbar {
    height: 80px;
  }
  header #topbar .button {
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
    display: block;
  }
  .headerinfo {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  #AccessibilityAnalysis .threesections .right #formcontainer {
    position: relative;
    z-index: 98;
    background-color: white;
    max-width: 455px;
    float: right;
    padding: 20px 15px;
    padding-bottom: 5px;
  }
  footer .row #footerblobs {
    position: absolute;
    bottom: 0px;
  }
  footer .row #footerblobs .FooterSmallBlobs {
    position: relative;
    left: 190px;
    top: -20px;
    z-index: 9;
  }
  footer .row #footerblobs .FooterBlobs {
    position: relative;
    left: 0px;
    z-index: 8;
  }
  footer .footermenu h6 {
    margin-bottom: 15px;
  }

}

@media only screen and (max-width: 430px) {
  #MainBanner .orangeblob {
    position: absolute;
    top: -5px;
    right: -150px;
  }

  h1,
  .h1 {
    font-size: 3.2rem!important;
  }
  h2,
  .h2 {
    font-size: 3rem!important;
  }
  h3,
  .h3 {
    font-size: 2.6rem!important;
  }
  h4,
  .h4 {
    font-size: 2.2rem!important;
  }
  h5,
  .h5 {
    font-size: 1.7rem;
  }
  h6,
  .h6 {
    font-size: 1.4rem;
  }
  p {
    font-size: 1.5rem;
  }
  a {
    font-size: 1.5rem;
  }
}
