@use "variables" as *;

footer {
  background-color: $darkgrey;
  flex: 0 0 100%;
  display: flex !important;
  justify-content: space-between;
  z-index: 999;
  position: relative;
  overflow: hidden;
  flex-wrap: wrap;
  .row {
    max-width: 1170px;
    margin: auto;
    flex: 0 0 100%;
    display: flex !important;
    justify-content: space-between;
    padding: 80px 15px;

    a,
    p, span {
      font-family: $reg;
      color: white;
      text-decoration: none;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 15px;
    }

    .FooterContact{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      flex-direction: column;
      *{
        color: white;
      }
      img{
        margin-right: 10px;
      }
    }
    .footer-logo {
      align-self: center;
      max-width: 265px;
      flex: 0 0 100%;
      img {
        max-width: 265px;
        padding: 0px;
        align-self: center;
      }
    }

    .footermenu {
      align-self: center;
      max-width: 140px;
      flex: 0 0 100%;

      h6 {
        color: white;
        font-family: $title;
        font-size: 1.6rem;
        font-weight: 700;
        margin-bottom: 35px;
      }
      ul {
        list-style: none;
        padding-left: 0px;
        li {
          margin-bottom: 2px;
          font-size: 1.6rem;
          font-family: $reg;
          font-weight: 400;
          a {
            font-size: 1.6rem;
            font-family: $reg;
            font-weight: 400;
          }
        }
        li:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .GooderMarketing {
      align-self: center;
      max-width: 265px;
      flex: 0 0 100%;
      img {
        margin-bottom: 25px;
      }
    }
  }
  .copyright{
    max-width: 320px;
  }
  .privacylinks{
    max-width: 675px;
    width: 100%;
    a{
      text-transform: uppercase;
      display: inline-block;
    }
  }
  .bottombar{
    background-color: $black;
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    flex-wrap: wrap;
    .leftside{
      background-color: $black;
      display: flex;
      justify-content: space-between;
      flex: 0 0 100%;
      max-width: 70%;
      padding: 20px;
      @media only screen and (max-width: 991px) {
        max-width: 100%;
      }
      @media only screen and (max-width: 576px) {
        max-width: 100%;
        flex-direction: column;
        row-gap: 20px;
      }
      a, *{
        color: $teal!important;
        align-self: center;
      }
      a, p{
        margin: 10px 25px;
      }
    }
  }
  .privacylinks{

  }

  .footersocial{
    background-color: $teal;
    display: flex;
    justify-content: flex-start;
    flex: 0 0 100%;
    max-width: 30%;
    padding: 20px;
    @media only screen and (max-width: 991px) {
      max-width: 100%;
      justify-content: center;
    }
    a{
      margin: 10px;
      align-self: center;
    }
    *{
      color: $black;
      font-size: 24px;
    }
  }
}
