// Breackpoints
$breakpoint-xxsm: 370px;
$breakpoint-xsm: 430px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 991px;
$breakpoint-xl: 1140px;
$breakpoint-xxl: 1240px;
$breakpoint-xxxl: 1440px;



//New Fonts
$title: "BEBAS", "Rajdhani", sans-serif;
$subtitle: "Rajdhani", "BEBAS", sans-serif;
$reg: "Oxanium", "Rajdhani", sans-serif;

$Bebas: "BEBAS", "Rajdhani", sans-serif;
$Rajdhani: "Rajdhani", "BEBAS", sans-serif;
$Oxanium: "Oxanium", "Rajdhani", sans-serif;

$darkblue: #1c1a4e;
$lightblue: #5a28f0;
$purple: #5a28f0;
$orange: #fd6344;


//New Colors
$teal: #0CE6DC;
$grey: #E6EDED;
$gold: #D6A112;
$darkgrey: #272727;
$white: #FFFFFF;
$black: #000000;