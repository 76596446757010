@use "variables" as *;


@font-face {
  font-family: "Bebas";
  src: url("../Fonts/Bebas_Neue/BebasNeue-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Oxanium";
  src: url("../Fonts/Oxanium/Oxanium-VariableFont_wght.ttf") format("truetype");
}
@font-face {
  font-family: "Rajdhani";
  src: url("../Fonts/Rajdhani/Rajdhani-Bold.ttf") format("truetype");
}


body,
html {
  font-size: 10px;
  font-family: $reg;
  color: $black;
  margin: 0px;
  overflow-x: hidden;
}

h1,
.h1 {
  font-size: 6.6rem;
  font-family: $title;
  color: $black;
}
h2,
.h2 {
  font-size: 5.2rem;
  font-family: $title;
  color: $black;
}
h3,
.h3 {
  font-size: 4.8rem;
  font-family: $title;
  color: $black;
}
h4,
.h4 {
  font-size: 3.2rem;
  font-family: $title;
  color: $black;
}
h5,
.h5 {
  font-size: 2.2rem;
  font-family: $title;
  color: $black;
}
h6,
.h6 {
  font-size: 1.8rem;
  font-family: $title;
  color: $black;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $black;
  margin-bottom: 20px;
  margin-top: 0px;
  line-height: 1.2;
  font-weight: normal;
}
p {
  font-size: 1.8rem;
  font-family: $reg;
  color: $black;
  //line-height: 1.5;
  line-height: 34px;
  margin-bottom: 2rem;
  width: 100%;
}
strong {
  font-weight: bold;
}
a {
  font-size: 1.6rem;
  //font-family: $reg;
}
a:focus,
button:focus {
  outline: 2px dotted #aeb0b5;
  outline-offset: 2px;
}
