@use "variables" as *;

.error-404{
  padding-top: 100px;
  padding-bottom: 100px;
  input:not(#searchsubmit){
    border: 2px solid black;
    border-radius: 15px;
    padding: 12px 25px !important;
    color: black;
    font-family: $Rajdhani;
    font-size: 1.8rem !important;
    background-color: white;
    width: 100%;
    max-width: calc(100% - 240px);
    margin-right: 15px;
    @media only screen and (max-width: 576px) {
      max-width: 100%;
      margin-right: 0px;
    }
  }
  #searchsubmit{
    background-color: transparent;
    color: #000000;
    padding: 10px 25px;
    font-family: $Rajdhani;
    font-weight: bold;
    border: 2px solid $teal;
    text-align: center;
    display: inline-block;
    border-radius: 10px;
    font-size: 1.8rem;
    line-height: 1.5;
    transition: all 0.75s ease-in-out;
    width: 100%;
    max-width: 220px;
    cursor: pointer;

    @media only screen and (max-width: 576px) {
      margin: auto;
      margin-top: 20px;
      display: block;
    }


    &:hover{
      text-decoration: none;
      background-color: $teal;
      border: 2px solid #FFFFFF;
      color: white;
      transition: all 0.75s ease-in-out;
    }
  }
}
#loading {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  opacity: 1;
  background-color: #000;
  z-index: 999999999;
}

#loading-image {
  position: relative;
  align-self: center;
  z-index: 100;
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 1s;
  -moz-transition-property: -moz-transform;
  -moz-transition-duration: 1s;
  -webkit-animation-name: grow;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: grow;
  -moz-animation-duration: 5s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  transition-property: -moz-transform;
  transition-duration: 1s;
  animation-name: grow;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.fireworks{
  position: absolute;
  z-index: 9;
  opacity: 0.7;
}


@-webkit-keyframes grow {
  from {
      -webkit-transform:scale(0.5);
  }
  to {
      -webkit-transform:scale(1.5);
  }
}
@-moz-keyframes grow {
  from {
    -webkit-transform:scale(0.5);
}
to {
    -webkit-transform:scale(1.5);
}
}
@keyframes grow {
  from {
    -webkit-transform:scale(0.5);
}
to {
    -webkit-transform:scale(1.5);
}
}


.subbanneform{
  position: relative;
  top: -50px;
  z-index: 111;
}

.SubBanner {

      p {
        color: white;
        font-size: 1.6rem;
        margin-bottom: 10px;
      }
      h1 {
        font-weight: $title;
        color: white;
        font-size: 3.2rem;
        font-weight: bold;
        margin-bottom: 25px;
      }

      ul.checkmark {
        list-style: none;
        margin-bottom: 30px;
        li:before {
          content: " ";
          background-image: url("../img/check-circle-outlined.png");
          background-size: contain;
          background-repeat: no-repeat;
          width: 17px;
          height: 17px;
          padding-right: 30px;
          left: 0px;
          position: absolute;
          color: black;
          background-position: top;
        }
        li {
          font-size: 1.6rem;
          font-family: $reg;
          color: white;
          margin-bottom: 10px;
          padding-left: 40px;
        }
        li:last-child {
          border-bottom: 0px;
        }
      }
      .subtitle {
        color: white;
        margin: auto;
        max-width: 455px;
        font-size: 2rem;
        font-family: $title;
        float: right;
        margin-bottom: 20px;
        padding: 0px 0px;
      }

      img {
        max-width: 100%;
        align-self: center;
        position: relative;
        max-width: calc(100% + 35px);
      }

      #formcontainer {
        position: relative;
        z-index: 98;
        background-color: white;
        max-width: 455px;
        float: right;
        padding: 20px 15px;
        padding-bottom: 5px;

        .labelname {
          display: none;
        }
        input[type="text"],
        input[type="email"] {
          margin-bottom: 10px;
          background: #f7f6fb;
          border: 1px solid #d9d6d6;
          box-sizing: border-box;
          width: 100%;
          font-size: 1.6rem;
          color: $darkblue;
          font-family: $reg;
          padding: 12px 20px;
          min-height: 48px;
        }
        label {
          width: 100%;
        }
        textarea {
          margin-bottom: 30px;
          background: #f7f6fb;
          border: 1px solid #d9d6d6;
          box-sizing: border-box;
          width: 100%;
          height: 92px;
          font-size: 1.6rem;
          color: $darkblue;
          font-family: $reg;
          padding: 12px 20px;
        }
        p {
          margin-bottom: 0px;
        }
        .button {
          border: 0px;
          width: 100%;
          text-align: center;
          cursor: pointer;
        }
      }

  
  .banner-shape {
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
    background-color: transparent;
    bottom: 0px;
    svg {
      display: block;
      width: calc(100% + 1.3px);
      position: relative;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      width: calc(120% + 1.3px);
      height: 80px;
      fill: white;
    }
  }
}




#HomepageSlider{

  width: 100%;
  min-height: 900px;
  max-width: 100%;
  justify-content: center;
  background-color: #000000;
  //background-image: url('../img/hero_bg.png');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  position: relative;
  @media only screen and (max-width: 991px) {
    min-height: fit-content!important;
    height: auto;
    max-height: fit-content;
    gap: 20px;
  }
  .slide{
    background-position: left; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain;
    min-height: 900px;
    height: 100vh;
    max-height: calc(100vh - 125px);
    overflow: hidden;
    position: relative;
    padding-top: 110px;
    padding-bottom: 60px;
    @media only screen and (max-width: 991px) {
      min-height: fit-content!important;
      height: auto;
      max-height: fit-content;
      gap: 20px;
    }
    @media only screen and (max-width: 991px) {
      background-size: cover;
    }


  }
  .subtitle{
    p{
      @media only screen and (max-width: 991px) {
        font-size: 12px!important;
      }
    }
  }
  .slidecontent{
    align-self: center;
    width: 100%;
    min-height: 900px;
    height: 100vh;
    @media only screen and (max-width: 991px) {
      min-height: fit-content!important;
      height: auto;
      max-height: fit-content;
      gap: 20px;
    }
  }
  .sliderbutton{
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    //max-width: 150px;
    height: 80px;
    padding: 30px 0px;
    color: white;
    @media only screen and (max-width: 480px) {
      margin-bottom: 30px;
      margin-left: 20px;
    }
    *{
      align-self: center;
    }
    img{
      margin-left: 20px;
    }
    &::before{
      transform: translateX(-20px);
      transition: transform 500ms ease-in-out;
      z-index: 1;
      content: '';
      display: block;
      position: absolute;
      height: 80px;
      width: 80px;
      border: 2px solid $teal;
      //left: -20px;
      border-radius: 300px;
      align-self: center;
    }
  }
  .sliderbutton:hover{
    &::before{
      transform: translateX(110px);
      transition: transform 500ms ease-in-out;
      //left: auto;
      //right: 0px;
    }
  }
  .slideinfo{
    display: flex;
    flex: 0 0 100%;
    max-width: 1170px;
    margin: auto;
    padding: 20px 0px;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    min-height: 900px;
    height: 100vh;

    @media only screen and (max-width: 991px) {
      min-height: fit-content!important;
      height: auto;
      max-height: fit-content;
      gap: 20px;
    }
    @media only screen and (max-width: $breakpoint-xxl) {
      padding: 20px 20px;
      padding-bottom: 60px;
    }
    @media only screen and (max-width: 1700px) {
      background: #0000008c;
      padding-bottom: 20px;
    }
    .subtitle{
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      flex: 0 0 100%;
      font-size: 24px;
      img{
        margin-right: 15px;
        align-self: center;
      }
      figure{
        margin: 0;
        margin-right: 15px;
      }
      p{
        align-self: center;
        margin: 0px;
        font-size: 24px;
      }
    }
    
    h1{
      font-size: 8rem;
      width: 100%;
      color: white;
      text-transform: unset;
      margin-bottom: 0;
    }
    p{
      font-size: 2rem;
      //max-width: 350px;
      //max-width: calc(100% - 240px);
      color: white;
      margin-top: 0;
      @media only screen and (max-width: $breakpoint-sm) {
        max-width: 100%;
        
      }
    }
    .sliderwidth{
      display: flex;
      flex: 0 0 100%;
      flex-wrap: wrap;
      @media only screen and (max-width: $breakpoint-xxl) {
        max-width: 100%;
      }
    }
    .sliderwidth.left{
      max-width: 1140px;
      padding-right: 15px;
      padding-left: 15px;
      display: flex;
      justify-content: flex-start;
      min-height: 220px;
      @media only screen and (max-width: 991px) {
        min-height: fit-content!important;
        height: auto;
        max-height: fit-content;
        gap: 20px;
      }
    }
    .sliderwidth.right{
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-right: 15px;

      padding-bottom: 60px;
      @media only screen and (max-width: 1700px) {
        padding-bottom: 15px;
      }
      @media only screen and (max-width: 991px) {
        min-height: fit-content!important;
        height: auto;
        max-height: fit-content;
        gap: 20px;
        padding-left: 15px;
      }
      .width500{
        max-width: 500px;
      }
    }

    .button{
      align-self: center;
    }

  }
  .slick-prev{
    display: block;
    height: 48px;
    width: 28px;
    background-color: transparent;
    font-size: 0px;
    position: absolute;
    bottom: 45%;
    border: 0px;
    left: 5%;
    transform: rotate(180deg)!important;
    z-index: 99;
    cursor: pointer;
    background: url('/img/sliderarrow.png') no-repeat;
    @media only screen and (max-width: $breakpoint-sm) {
      left: 5px;
    }
  }

  .slick-next{
    display: block;
    height: 48px;
    width: 28px;
    position: absolute;
    background-color: transparent;
    font-size: 0px;
    bottom: 45%;
    right: 5%;
    border: 0px;
    z-index: 99;
    cursor: pointer;
    background: url('/img/sliderarrow.png') no-repeat;
    @media only screen and (max-width: $breakpoint-sm) {
      right: 5px;
    }
  }
  .slick-dots{
    z-index: 999;
    position: absolute;
    width: auto;
    bottom: 45%;
    left: 53%;
    list-style: none;
    display: flex !important;
    flex-wrap: nowrap;
    flex-direction: row;
    display: none!important;
    @media only screen and (max-width: $breakpoint-xxl) {
      left: auto;
      right: auto;
      bottom: 0px;
      width: 100%;
      text-align: center;
      justify-content: center;
    }
    list-style: none;
    li{
      align-self: center;
    }
    button{
      font-size: 0px;
      width: 12px;
      height: 12px;
      background-color: transparent;
      border: 1.5px solid $teal;
      border-radius: 300px;
      margin: 5px;
      max-width: 12px;
      max-height: 12px;
      padding: 0px;
      cursor: pointer;

    }
    .slick-active{
      button{
        width: 17px;
        height: 17px;
        background-color: $teal;
      }
    }
    }
}
.slick-prev, .slick-next{
  font-size: 0px!important;
  min-height: 50px!important;
}
form{
  textarea{
    background-image: url('/img/textareabg.png');
    background-repeat: no-repeat;
    background-position: 99.5% 97%;
    background-size: 24px;
  }
}


#DiscoverySlider{
  background-image: url('/img/discoverybg.jpg');
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  min-height: 888px;
  display: flex;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 991px) {
    background-size: cover;
  }

  .serviceinfo{
    display: flex;
    flex-wrap: nowrap;
    flex: 0 0 100%;
    margin-bottom: 40px;

    img{
      margin-right: 15px;
      align-self: center;
    }
    p{
      margin: 0;
      align-self: center;
      font-family: $subtitle;
      font-size: 24px;
      font-weight: bold;
    }
  }
  .rownum{
   font-size: 55px;
  }
  .slidecount{
    font-size: 32px;
  }
  h2{
    margin-bottom: 40px;
    font-size: 66px;
  }
  .slickslidercontainer{
    max-width: 1170px;
    width: 100%;
    margin: auto;
    align-self: center;
  }
  .slidewidth{
    background-color: white;
    max-width: 800px;
    padding: 80px;
    padding-right: 240px;
    padding-left: 15px;

    @media only screen and (max-width: 1340px) {
      background-color: white;
      max-width: 100%;
      padding: 80px 15px;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  .slick-prev{
    display: block;
    height: 48px;
    width: 28px;
    background-color: transparent;
    font-size: 0px;
    position: absolute;
    bottom: 45%;
    border: 0px;
    left: -15%;
    transform: rotate(180deg)!important;
    z-index: 99;
    cursor: pointer;
    background: url('/img/tealsliderarrow.png') no-repeat;

    @media only screen and (max-width: 1340px) {
      left: 0px;
      bottom: -50px;
    }
  }

  .slick-next{
    display: block;
    height: 48px;
    width: 28px;
    position: absolute;
    background-color: transparent;
    font-size: 0px;
    bottom: 45%;
    right: -15%;
    border: 0px;
    z-index: 99;
    cursor: pointer;
    background: url('/img/tealsliderarrow.png') no-repeat;
    @media only screen and (max-width: 1340px) {
      right: 0px;
      bottom: -50px;
    }
  }
  .slick-dots{
    z-index: 999;
    position: absolute;
    width: auto;
    bottom: 40%;
    left: 53%;
    list-style: none;
    display: flex !important;
    flex-wrap: nowrap;
    flex-direction: row;
    @media only screen and (max-width: $breakpoint-xxl) {
      left: auto;
      right: auto;
      bottom: 0px;
      width: 100%;
      text-align: center;
      justify-content: center;
    }
    list-style: none;
    li{
      align-self: center;
    }
    button{
      font-size: 0px;
      width: 12px;
      height: 12px;
      background-color: transparent;
      border: 1.5px solid $teal;
      border-radius: 300px;
      margin: 5px;
      max-width: 12px;
      max-height: 12px;
      padding: 0px;
      cursor: pointer;

    }
    .slick-active{
      button{
        width: 17px;
        height: 17px;
        background-color: $teal;
      }
    }
    }
}

#DiscoverySlider.phonebanner{
  background-image: url('/img/phonebanner.png');

}
#DiscoverySlider.VideoBGslider{
  background: none!important;
  video{
    width: 100%;
    height: auto;
    z-index: -1;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }
  .videobg{
    display: flex;
    height: -moz-max-content;
    height: 100%;
    width: 75%;
    position: absolute;
    z-index: -1;
    left: 45%;
    top: 0px;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    @media only screen and (max-width: 991px) {
      width: 100%;
      position: absolute;
      z-index: -1;
      left: 0%;
    }
  }
}
.letstalkform{
  position: relative;
  .gooderchair{
    position: absolute;
    right: -200px;
    bottom: -145px;
    width: 656px;
    @media only screen and (max-width: 1140px) {
      right: -40px;
      bottom: -205px;
      width: 100%;
      max-width: 400px;
    }
    img{

    }
  }
  .ginput_container_email{
    input{
      max-width: 630px!important;
      max-height: 88px;
      @media only screen and (max-width: 1140px) {
        max-width: 100%!important;
      }
    }
  }
  .gfield_required{
    display: none!important;
  }
  .gform_footer {
    max-width: 250px;
    position: absolute;
    bottom: -22px;
    z-index: 9999;
    left: 547px;
    @media only screen and (max-width: 1140px) {
      left: auto;
    }
      input{
        padding: 21px !important;
      }
  }
}

.gform_wrapper.gravity-theme .gfield_label, .gchoice, .gform_wrapper.gravity-theme .gfield_checkbox label, .gform_wrapper.gravity-theme .gfield_radio label{
  color: white;
}
.gfield--type-captcha{
  margin-top: 15px;
}
.team{
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  margin: auto;
  padding: 0px;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  .team-member{
    flex: 0 0 100%;
    max-width: 25%;
    -o-object-fit: cover;
    object-fit: cover;
    min-height: 500px;
    overflow: hidden;
    position: relative;
    height: 500px;
    @media only screen and (max-width: 1140px) {
      max-width: 50%;
    }
    @media only screen and (max-width: 567px) {
      max-width: 100%;
    }
  }
  .bg{
    position: absolute;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    height: 500px;
    width: 100%;

  }
  .hover{
    display: none;
    padding: 20px;
    border: 2px solid #0CE6DC;
    background-color: #0000008a;
    height: 100%;
    width: 100%;
    position: relative;
    max-width: calc(100% - 40px);
    margin: 20px;
    max-height: calc(100% - 40px);
    justify-content: space-between;
  }
  .team-member:hover, .team-member:focus{
    .hover{
      display: flex;
      z-index: 99;
    }
    .teamname{
      align-self: flex-start;
      width: 100%;
      color: white;
      text-align: left;
      position: absolute;
      top: 15px;
      left: 15px;
      font-size: 1.8rem;
      font-weight: bold;
      font-family: $Rajdhani;
      align-self: flex-start;
      display: flex;
      flex: 0 0 100%;
      max-width: 100%;
      justify-content: flex-start;
      flex-wrap: wrap;
      img{
        margin-right: 15px;
      }
    }
    h4{
      width: 100%;
      color: white;
      text-align: left;
      font-size: 2.4rem;
      margin-bottom: 10px;
      font-family: $Rajdhani;
      font-weight: bold;
    }
    p{
      width: 100%;
      color: white;
      text-align: left;
      margin: 0px;
      font-family: $Rajdhani;
      font-weight: bold;
      font-size: 1.6rem;
    }
    .teamsocial{
      align-self: flex-end;
      display: flex;
      flex: 0 0 100%;
      max-width: 100%;
      justify-content: center;
      width: 100%;
      color: white;
      text-align: right;
      position: absolute;
      bottom: 15px;
      right: 15px;
      font-size: 1.8rem;
      font-weight: bold;
      font-family: $Rajdhani;
      i{
        color: white;
        margin: 10px;
      }
    }
  }
}

.logooverlap{
  position: absolute;
  top: 100px;
  right: -100px;
  z-index: 99;
  width: 1100px;
  height: auto;
  @media only screen and (max-width: 991px) {
    bottom: -375px;
    right: -25%;
    top: auto;
    z-index: 1;
    width: 900px;
  }
  @media only screen and (max-width: 768px) {
    width: 800px;
    bottom: -333px;
  }
  @media only screen and (max-width: 576px) {
    bottom: -290px;
    right: -40%;
    top: auto;
    z-index: 1;
    width: 700px;
  }
}

.searchbox{
  background: $black!important;
  h4{
    color: $white!important;
  }
}
#HomepageVideoBanner{
  position: relative;
  min-height: 480px;
  height: 100vh;
  max-height: calc(100vh - 125px);
  .slidecontent{
    position: absolute;
    width: 100vw;
    bottom: 0px;
    background-color: #00539be0;
    z-index: 99;
  }
  iframe{
    //height: 100vh;
    //width: 100%;
  }
  video {
      object-fit: cover;
      object-position: center;
      width: 100%;
      min-height: 480px;
      height: 100vh;
      max-height: calc(100vh - 125px);
      position: absolute;
      z-index: 9;
      top: 0vh;
  }
  .slideinfo{
    display: flex;
    flex: 0 0 100%;
    max-width: 1140px;
    margin: auto;
    padding: 20px 0px;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;

    h1{
      font-size: 5.8rem;
      font-weight: bolder;
      width: 100%;
      color: white;
      text-transform: capitalize;
      margin-bottom: 0;
    }
    p{
      font-size: 1.8rem;
      //max-width: 350px;
      max-width: calc(100% - 240px);
      color: white;
      margin-top: 0;
      @media only screen and (max-width: $breakpoint-sm) {
        max-width: 100%;
        
      }
    }
    .sliderwidth{
      display: flex;
      flex: 0 0 100%;
      max-width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .button{
      align-self: center;
    }

  }
  
}


.breadcrumb{
  background-color: transparent!important;
}





#SubpageVideoBanner{
  position: relative;
  min-height: 540px;
  height: 100vh;
  max-height: calc(100vh - 125px);
  @media only screen and (max-width: 768px) {
    //height: auto;
    //max-height: max-content;
  }

  .slidecontent{
    position: absolute;
    width: 100vw;
    bottom: 0px;
    background-color: #00539be0;
    z-index: 99;
  }
  iframe{
    //height: 100vh;
    //width: 100%;
  }
  video {
      object-fit: cover;
      object-position: center;
      width: 100%;
      min-height: 480px;
      height: 100vh;
      max-height: calc(100vh - 125px);
      position: absolute;
      z-index: 9;
      top: 0vh;
  }

  .slideinfo{
    display: flex;
    flex: 0 0 100%;
    max-width: 1140px;
    margin: auto;
    padding: 40px 0px;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    @media only screen and (max-width: 1240px) {
      padding: 40px 15px;
    }
    h1{
      font-size: 5.8rem;
      font-weight: bolder;
      width: 100%;
      color: white;
      text-transform: capitalize;
      margin-bottom: 0;
    }
    
    p{
      font-size: 1.8rem;
      max-width: 100%;
      color: white;
      margin-top: 0;
    }
    .sliderwidth{
      display: flex;
      flex: 0 0 100%;
      max-width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .button{
      align-self: center;
    }

  }
  .breadcrumbrow{
    max-width: 100%;
    margin: auto;
    min-height: 60px;
    padding: 10px;
    display: flex;
    flex: 0 0 100%;
    justify-content: center;
    position: absolute;
    z-index: 99;
    bottom: 0;
    width: 100%;
    display: none;
    .breadcrumb {
      font-size: 1.8rem;
      font-family: $reg;
      display: flex;
      flex: 0 0 100%;
      max-width: 1140px;
      position: relative;
      margin-left: -20px;
      align-self: center;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;

      i{
        font-size: 2.2rem;
      }
      * {
        color: $darkblue;
      }
      a,
      .breadcrumb_last, span{
        font-weight: 500;
        font-size: 1.8rem;
        font-family: $reg;
        align-self: center;
        margin-right: 5px;
        margin-left: 5px;
        text-transform: uppercase;
        position: relative;
        bottom: -1.5px;
        display: inline-block;
        @media only screen and (max-width: 768px) {
          margin: 2.5px;
        }
      }
    }
  }
}

body:not(.home){
  #SubpageVideoBanner{
    position: relative;
    min-height: 440px;
    height: 440px;

    @media only screen and (max-width: 768px) {
      height: auto;
      max-height: max-content;
    }

    
    .slidecontent{
      position: absolute;
      width: 100vw;
      bottom: 60px;
      background-color: #00539be0;
      z-index: 99;
    }
    iframe{
      //height: 100vh;
      //width: 100%;
    }
    video {
        object-fit: cover;
        object-position: center;
        width: 100%;
        min-height: 440px;
        height: 440px;
        position: absolute;
        z-index: 9;
        top: 0vh;
    }
    .breadcrumbrow{
      display: flex;
    }
  }
}

.imagetitlebox{
  position: relative;
  .col{
    position: relative;
    overflow: hidden;
    margin-bottom: 40px;
    border: 5px solid white;
    outline: 1.5px solid black;
    -webkit-box-shadow: 0px 8px 10px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 8px 10px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 8px 10px 2px rgba(0, 0, 0, 0.3);
    margin: 15px;
    padding: 0px;
    max-width: calc(50% - 30px);
    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
  }
  .imgbox{
    img{
      min-height: 500px;
      height: 500px;
      object-fit: cover;
      object-position: center;
      margin-bottom: 0px;
    }
  }
  .boxcontent{
    position: absolute;
    width: 100%;
    bottom: 0px;
    background-color: rgba(0, 83, 155, 0.8784313725);
    padding: 15px;
    h4{
      margin: 0px;
      margin-bottom: 15px;
      font-size: 3.2rem;
      font-weight: bolder;
      width: 100%;
      color: white;
      text-transform: uppercase;
      line-height: 1.5;
      a{
        margin: 0px;
        font-size: 3.2rem;
        font-weight: bolder;
        width: 100%;
        color: white;
        text-transform: uppercase;
        line-height: 1.5;
        text-decoration: none;
      }

    }
    a:not(.button){
      color: $white!important;
    }
    p, a, span, i, em, strong{
      color: $white!important;
    }
    p{
      margin-top: 0px;
      margin-bottom: 15px;
    }
  }
}



body:not(.home){
  @media only screen and (max-width: 768px) {
    #SubpageBanner .row .leftside{
      padding-bottom: 20px;
    }
    #SubpageBanner .row .rightside{
      padding-bottom: 60px;
    }
  }
}

#SubpageBanner {
  background-color: $black;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: relative;
  z-index: 99;

  @media only screen and (max-width: 768px) {
    height: auto;
    max-height: max-content;
  }


  .row {
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    z-index: 101;
    max-width: 1170px;
    padding: 0px 15px;
    margin: auto;
    padding-top: 180px;
    .leftside{
      display: flex;
      flex: 0 0 100%;
      flex-wrap: wrap;
      position: relative;
      max-width: 50%;
      padding-bottom: 90px;
      z-index: 99;
      @media only screen and (max-width: 678px) {
        max-width: 100%;
      }
      .bannercontent {
        max-width: 100%;
        margin: auto;
        flex: 0 0 100%;
        align-self: center;
        padding: 20px 0px;
        @media only screen and (max-width: 1240px) {
          padding: 20px 15px;
        }
        h1 {
          font-family: $title;
          font-weight: bold;
          color: white;
          font-size: 6.6rem;
          text-transform: uppercase;
          margin-bottom: 0px;
          @media only screen and (max-width: 1240px) {
            font-size: 5.2rem;
          }
          @media only screen and (max-width: 991px) {
            font-size: 4.2rem;
          }
        }
        h1, h2, h3, h4, h5, h6 {
          color: white;
        }
        p{
          font-size: 1.8rem;
          margin-top: 20px;
          color: white;
        }
        a:link{
          color: white;
          text-decoration: none;
        }
      }
    }

    .rightside{
      position: relative;
      display: flex;
      flex: 0 0 100%;
      flex-wrap: wrap;
      position: relative;
      max-width: 50%;
      justify-content: flex-end;
      @media only screen and (max-width: 678px) {
        max-width: 100%;
      }
      .bannerbg {
        width: 100%;
        max-width: 300px;
        z-index: 5;
        align-self: center;
      }
      .bannerlogo{
        position: absolute;
        right: -125px;
        bottom: -127px;
        width: 540px;
        height: auto;
        z-index: 1;
      }
    }

  }

  .breadcrumbrow{
    max-width: 100%;
    margin: auto;
    min-height: 60px;
    padding: 0px;
    display: flex;
    flex: 0 0 100%;
    justify-content: center;
    position: relative;
    z-index: 99;
    margin-bottom: 10px;
    flex-wrap: wrap;
    .breadcrumb {
      font-size: 1.8rem;
      font-family: $reg;
      display: flex;
      flex: 0 0 100%;
      max-width: 1140px;
      position: relative;
      align-self: center;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      color: $white;
      flex-wrap: wrap;
      padding-left: 0px;
      i{
        font-size: 2.2rem;
      }
      a {
        color: $teal;
        border: 0px;
      }
      a,
      .breadcrumb_last, span{
        font-weight: 500;
        font-size: 1.8rem;
        font-family: $reg;
        align-self: center;
        //margin-right: 10px;
        //margin-left: 10px;
        padding: 0px;
        text-transform: capitalize;
        position: relative;
        display: inline-block;
        text-decoration: none;
      }
      span{
        padding: 5px 10px;
      }
      span:first-child{
        margin-left: 0px;
        padding-left: 0;
        padding-right: 5px;
        text-transform: capitalize;
      }
      .current-item{
        color: $white!important;
      }
    }
  }

}
.gap5 .is-layout-flex{
  gap: 5px!important;
}
.gap10 .is-layout-flex{
  gap: 10px!important;
}

/*
body:not(.home){

  #SubpageBanner {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    position: relative;
    min-height: 440px;
    height: 500px;
    max-height: 500px;

    z-index: 99;
    @media only screen and (max-width: 768px) {
      min-height: 340px!important;
      height: max-content!important;
    }

    
    .bannerbg {
      width: 100%;
      max-width: 100%;
      object-fit: cover;
      position: absolute;
      z-index: 97;
      min-height: 440px;
      height: 440px;
      object-position: top;
      z-index: 5;
      @media only screen and (max-width: 768px) {
        min-height: 300px!important;
        height: 300px!important;
      }
    }
    .row {
      display: flex;
      flex: 0 0 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;
      min-height: 440px;
      height: 440px;

      z-index: 101;
      max-width: 100%;
      padding: 0px;

      @media only screen and (max-width: 768px) {
        min-height: 300px!important;
        height: 300px!important;
      }
      .bannerbgcolor{
        background-color: rgba(0, 83, 155, 0.8784313725);
        max-width: 100%;
        flex: 0 0 100%;
        align-self: flex-end;
      }
      .bannercontent {
        max-width: 1140px;
        margin: auto;
        flex: 0 0 100%;
        align-self: center;
        padding: 20px 0px;
        @media only screen and (max-width: 1240px) {
          padding: 20px 15px;
        }
        h1 {
          font-family: $title;
          font-weight: bold;
          color: white;
          font-size: 5.8rem;
          text-transform: capitalize;
          margin-bottom: 0px;
          @media only screen and (max-width: 1240px) {
            font-size: 5.2rem;
          }
          @media only screen and (max-width: 991px) {
            font-size: 4.2rem;
          }
        }
        p{
          font-size: 1.8rem;
          margin-top: 20px;
          color: white;
          @media only screen and (max-width: 768px) {
            display: none;
          }
        }
      }
    }
    .breadcrumbrow{
      background-color: $orange;
      max-width: 100%;
      margin: auto;
      min-height: 60px;
      padding: 10px;
      display: flex;
      flex: 0 0 100%;
      justify-content: center;
      position: relative;
      z-index: 99;

      .breadcrumb {
        font-size: 1.8rem;
        font-family: $reg;
        display: flex;
        flex: 0 0 100%;
        max-width: 1140px;
        position: relative;
        margin-left: -20px;
        align-self: center;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        @media only screen and (max-width: 768px) {
          flex-wrap: wrap;
        }
        i{
          font-size: 2.2rem;
        }
        * {
          color: $darkblue;
        }
        a,
        .breadcrumb_last, span {
          font-weight: 500;
          font-size: 1.8rem;
          font-family: $reg;
          align-self: center;
          margin-right: 5px;
          margin-left: 5px;
          text-transform: uppercase;
          position: relative;
          display: inline-block;
          @media only screen and (max-width: 768px) {
            margin: 2.5px;
          }
        }
      }
    }
  
  }

}
  */

#SubpageBanner.breadcrumbonly, body:not(.home) #SubpageBanner.breadcrumbonly{
  height: auto!important;
  background-color: $orange!important;
  max-width: 100%!important;
  min-height: 60px!important;
}



#ServicesSlider{
  max-width: 130%;
  width: 200%;
  position: relative;
  left: -15%;

  @media only screen and (max-width: 1140px) {
    max-width: 100%;
    width: 100%;
    position: relative;
    left: 0%;
  }

  .slidericons{
    display: flex;
    justify-content: flex-start;
    margin-top: 60px;
    @media only screen and (max-width: 576px) {
      margin-top: 20px;
    }
    img{
      align-self: center;
      margin-right: 10px;
    }
    p{
      align-self: center;
      font-weight: bold;
      margin: 0px;
    }
  }
  .slick-dots{
    display: flex;
    justify-content: center;
    flex: 0 0 130%;
    @media only screen and (max-width: 1140px) {
      flex: 0 0 100%;
    }
    list-style: none;
    li{
      align-self: center;
    }
    button{
      font-size: 0px;
      width: 12px;
      height: 12px;
      background-color: transparent;
      border: 1.5px solid $teal;
      border-radius: 300px;
      margin: 5px;
      max-width: 12px;
      max-height: 12px;
      padding: 0px;
      cursor: pointer;

    }
    .slick-active{
      button{
        width: 17px;
        height: 17px;
        background-color: $teal;
      }
    }
  }
  .slide{
    background-color: black;
    padding: 0px 25px;
    border-radius: 14px;
    margin: 15px;
    height: 457px;
    @media only screen and (max-width: 576px) {
      height: auto;
    }
    p, h5, a, div{
      color: $white;
    }
    h5{
      font-size: 38px;
      margin-top: 40px;
      margin-bottom: 40px;
      @media only screen and (max-width: 1700px) {
        font-size: 32px;
      }
      @media only screen and (max-width: 1500px) {
        font-size: 28px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
    .sliderbutton{
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      max-width: 150px;
      height: 80px;
      padding: 30px 0px;
      @media only screen and (max-width: 576px) {
        margin-bottom: 30px;
        margin-left: 20px;
      }
      *{
        align-self: center;
      }
      img{
        margin-left: 20px;
      }
      &::before{
        transform: translateX(0px);
        transition: transform 500ms ease-in-out;
        z-index: 1;
        content: '';
        display: block;
        position: absolute;
        height: 80px;
        width: 80px;
        border: 2px solid $teal;
        left: -20px;
        border-radius: 300px;
        align-self: center;
      }
    }
    .sliderbutton:hover{
      &::before{
        transform: translateX(95px);
        transition: transform 500ms ease-in-out;
        //left: auto;
        //right: 0px;
      }
    }
    .innerslide{
      display: flex;
      justify-content: space-between;
      flex: 0 0 100%;
         @media only screen and (max-width: 576px) {
            flex-wrap: wrap;
          }
    }
    .imgbox{
      width: 40%;
      position: relative;
      height: 457px;
      max-width: 35%;
      display: flex;
      flex: 0 0 100%;
      @media only screen and (max-width: 576px) {
        height: auto;
        max-width: 100%;
      }

      img{
        align-self: center;
        @media only screen and (max-width: 576px) {
          max-width: 80%;
          padding: 20px;
          margin: auto;
        }
      }
      &::after{
        content: "";
        background-image: url("../img/glow.png");
        position: absolute;
        right: -40px;
        top: 0px;
        background-size: contain;
        background-repeat: no-repeat;
        width: 65px;
        height: 457px;
        z-index: 99;
        @media only screen and (max-width: 576px) {
          display: none;
        }
      }
    }
    .slideinfo{
      width: 50%;
      @media only screen and (max-width: 576px) {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
.portfoliopage{
  padding-top: 120px;
}
#PortfolioSlider{

  .slidericons{
    display: flex;
    justify-content: flex-start;
    margin-top: 60px;
    img{
      align-self: center;
      margin-right: 10px;
    }
    p{
      align-self: center;
      font-weight: bold;
      margin: 0px;
    }
  }
  .slick-dots{
    display: flex;
    justify-content: center;
    flex: 0 0 100%;
    list-style: none;
    margin-top: 70px;
    li{
      align-self: center;
    }
    button{
      font-size: 0px;
      width: 12px;
      height: 12px;
      background-color: transparent;
      border: 1.5px solid $teal;
      border-radius: 300px;
      margin: 5px;
      max-width: 12px;
      max-height: 12px;
      padding: 0px;
      cursor: pointer;

    }
    .slick-active{
      button{
        width: 17px;
        height: 17px;
        background-color: $teal;
      }
    }
  }
  .slide{
    padding: 0px 25px;
    border-radius: 14px;
    margin: 15px;
    //opacity: 0;
    p, h5, a, div{
      color: $white;
    }
    h5{
      font-size: 44px;
      margin-top: 40px;
      margin-bottom: 40px;

    }
    .sliderbutton{
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      max-width: 150px;
      height: 80px;
      padding: 30px 0px;
      *{
        align-self: center;
      }
      img{
        margin-left: 20px;
      }
      &::before{
        transform: translateX(0px);
        transition: transform 500ms ease-in-out;
        z-index: 1;
        content: '';
        display: block;
        position: absolute;
        height: 80px;
        width: 80px;
        border: 2px solid $teal;
        left: -20px;
        border-radius: 300px;
        align-self: center;
      }
    }
    .sliderbutton:hover{
      &::before{
        transform: translateX(95px);
        transition: transform 500ms ease-in-out;
        //left: auto;
        //right: 0px;
      }
    }
    .innerslide{
      display: flex;
      justify-content: space-between;
      flex: 0 0 100%;
      flex-wrap: wrap;
    }
    .imgbox{
      width: 66.66%;
      position: relative;
      max-width: 66.66%;
      display: flex;
      flex: 0 0 100%;
      right: -45px;
      @media only screen and (max-width: 991px) {
        width: 100%;
        position: relative;
        max-width: 100%;
        display: flex;
        flex: 0 0 100%;
        right: 0px;
      }
      img{
        align-self: center;
      }
    }
    .slideinfo{
      width: 33.33%;
      align-self: flex-end;
      padding-bottom: 60px;

      @media only screen and (max-width: 991px) {
        width: 100%;
      }
      @media only screen and (max-width: 768px) {
        padding-top: 80px;
      }
      @media only screen and (max-width: 480px) {
        padding-top: 100px;
      }
      img{
        width: 100%;
        max-width: 225px;
        margin-bottom: 60px;
      }
      .button{
        margin-top: 40px;
      }
    }
  }
  .slide.slick-active{
    //opacity: 1;
  }
}

#TestimonialSlider{

  .slide{
    padding-left: 20PX;
  }
  .slick-prev {
    display: inline-block;
    position: absolute;
    left: -650px;
    top: 40%;
    transform: rotate(180deg)!important;
    @media only screen and (max-width: 1700px) {
      left: -400px;
    }
    @media only screen and (max-width: 1400px) {
      left: 0px;
      top: 100%;
    }
    @media only screen and (max-width: 991px) {
      top: 110%;
    }
  }
  button{
    cursor: pointer;
  }
  .slick-next {
    display: inline-block;
    position: absolute;
    right: -250px;
    top: 40%;
    @media only screen and (max-width: 1700px) {
      right: -100px;
    }
    @media only screen and (max-width: 1400px) {
      right: 0px;
      top: 100%;
    }
    @media only screen and (max-width: 991px) {
      top: 110%;
    }
  }
  button:after{
    margin-left: 0px;
  }
  .innerslide{
    display: flex;
    justify-content: space-between;
    flex: 0 0 100%;
    flex-wrap: wrap;
  }
  .imgbox{
    width: 33%;
    position: relative;
    max-width: 33%;
    display: flex;
    flex: 0 0 100%;
    justify-content: center;
    @media only screen and (max-width: 991px) {
      width: 100%;
      position: relative;
      max-width: 100%;
      display: flex;
      flex: 0 0 100%;
      right: 0px;
    }
    img{
      max-width: 375px;
      align-self: center;
      @media only screen and (max-width: 991px) {
        max-width: 300px;
      }
    }
  }
  .slideinfo{
    width: 66%;
    align-self: flex-start;
    padding-bottom: 60px;
    padding-left: 60px;

    @media only screen and (max-width: 991px) {
      width: 100%;
      padding-left: 0px;
    }
    @media only screen and (max-width: 768px) {
      padding-top: 60px;
    }

    .slidericons{
      display: flex;
      justify-content: space-between;
      flex: 0 0 100%;
      flex-wrap: wrap;
      position: relative;
      flex-wrap: nowrap;
      padding-left: 20PX;
      .left{
        flex: 0 0 100%;
        max-width: calc(100% - 175px);
      }
      .starsicon{
        align-self: flex-start;
        margin-bottom: 50px;
      }
      .quote{
        z-index: -1;
      }
      h2{
        width: 100%;
      }
    }
    .authorinfo{
      display: flex;
      justify-content: flex-start;
      flex: 0 0 100%;
      font-size: 2.4rem;
      font-family: $Rajdhani;
      h6{
        margin-bottom: 0px;
        font-size: 2.4rem;
        font-family: $Rajdhani;
        font-weight: 500;

      }
      img{
        align-self: center;
        margin-right: 15px;
        width: 17px;
        height: 17px;
      }
    }
    h2{
      font-size: 6.6rem;
    }
    h2, *{
      color: white;
    }

  }
}
#stayconnected{
  p{
    width: auto!important;
  }
}
#LandingBanner{
  @media only screen and (max-width: 991px) {
    min-height: fit-content !important;
    padding-bottom: 80px;
  }
}
.singleserviceimg{
  position: relative;
    top: -115px;
    z-index: 99;
}
.whitebgleft{
  &::before{
    content: '';
    display: block;
    position: absolute;
    right: 0px;
    width: 2000%;
    height: 100%;
    background-color: white;
    z-index: -1;
  }
}

.whitebgright{
  &::before{
    content: '';
    display: block;
    position: absolute;
    left: 0px;
    width: 2000%;
    height: 100%;
    background-color: white;
    z-index: -1;
  }
}
#portfolio-categories, #pagination{
  display: none!important;
}
#portfolio-categories, #categories{
  justify-content: center;
  display: flex;
  max-width: 100%;
  flex: 0 0 100%;
  margin-bottom: 80px;
  margin-top: 20px;
  flex-wrap: wrap;
  display: none!important;
  button{
    margin: 5px 10px;
    background-color: transparent;
    border: 0px;
    color: black;
    cursor: pointer;
    font-size: 24px;
    font-weight: bold;
    font-family: $Rajdhani;
  }
  button:hover, button:focus, button.active{
    color: $teal;
  }
}

#portfolio-posts{
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 40px;

  a{
    display: block;
  }
  .headinglink{
    margin: auto;
  }
  .bg{
    position: absolute;
    //object-fit: cover;
    object-fit: contain;
    padding: 40px;
    object-position: center;
    height: 570px;
    @media only screen and (max-width: 991px) {
      height: 400px;
    }
    @media only screen and (max-width: 768px) {
      height: 300px;
    }
  }
  .servicetype{
    max-width: calc(50% - 10px);
    width: 100%;
    margin-bottom: 20px;
    object-fit: contain;
    min-height: 570px;
    overflow: hidden;
    position: relative;
    height: 570px;
    align-self: start;

    @media only screen and (max-width: 991px) {
      height: 400px;
      min-height: 400px;
      max-width: 100%;
    }
    @media only screen and (max-width: 768px) {
      height: 300px;
      min-height: 300px;
    }
    
    .linkbutton{
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      max-width: 150px;
      height: 80px;
      padding: 30px 0px;
      color: white;
      margin: 0px auto;
      align-self: flex-end;
      @media only screen and (max-width: 480px) {
        margin-bottom: 30px;
        margin-left: 20px;
      }
      *{
        align-self: center;
      }
      img{
        margin-left: 20px;
      }
      &::before{
        transform: translateX(0px);
        transition: transform 500ms ease-in-out;
        z-index: 1;
        content: '';
        display: block;
        position: absolute;
        height: 80px;
        width: 80px;
        border: 2px solid $teal;
        left: -20px;
        border-radius: 300px;
        align-self: center;
      }
    }
    .linkbutton:hover{
      &::before{
        transform: translateX(95px);
        transition: transform 500ms ease-in-out;
        //left: auto;
        //right: 0px;
      }
    }

    .hover{
      display: none;
      padding: 20px;
      border: 2px solid #0CE6DC;
      background-color: rgb(0 0 0 / 82%);
      height: 100%;
      width: 100%;
      position: relative;
      max-width: calc(100% - 40px);
      left: 20px;
      top: 20px;
      min-height: 530px;
      max-height: calc(100% - 40px);
      justify-content: center;
      flex-direction: column;
      @media only screen and (max-width: 991px) {
        height: 360px;
        min-height: 360px;
      }
      @media only screen and (max-width: 768px) {
        height: 260px;
        min-height: 260px;
      }

    }
    h3{
      margin: auto;
      align-self: center;
      //font-size: 32px;
      color: white;
      text-align: center;
      width: 100%;
    }
    .servicetitle{
      display: flex;
      justify-content: flex-start;
      flex: 0 0 100%;
      max-width: 100%;
      flex-wrap: nowrap;
      position: absolute;
      top: 15px;
      left: 15px;
      font-family: $Rajdhani;
      font-size: 24px;
      color: white;
      a{
        color: white;
        margin: 0px;
        font-family: $Rajdhani;
        font-size: 18px;
        font-weight: bold;
        margin-top: 3px;
      }
      img{
        margin-right: 15px;
        align-self: center;
        margin-left: 25px;
      }
      span{
        align-self: center;
        margin: 0px;
        font-family: $Rajdhani;
        font-size: 24px;
      }
    }
  }
  .servicetype:hover, .servicetype:focus, .hovertrigger:hover, .hovertrigger:focus{
    .hover{
      display: flex;
      z-index: 99;
    }
    .learnmore{
      align-self: flex-end;
      width: 100%;
      color: white;
      text-align: right;
      position: absolute;
      bottom: 15px;
      right: 15px;
      font-size: 1.8rem;
      font-weight: bold;
      font-family: $Rajdhani;
      margin-bottom: 0px;
      img{
        margin-left: 15px;
      }
    }
  }
  .servicetype:nth-child(7n+7){
    max-width: 100%;
  }

}
.arrowcontent{
  @media only screen and (max-width: 768px) {
    p{
      font-size: 1.2rem!important;
    }
  }
}
#load-more{
  margin: auto;
  background-color: transparent;
  color: $black;
  padding: 10px 25px;
  font-family: $Rajdhani;
  font-weight: bold;
  border: 1px solid $teal;
  text-align: center;
  display: inline-block;
  border-radius: 36px;
  font-size: 1.8rem;
  line-height: 1.5;
  transition: all 0.75s ease-in-out;
  cursor: pointer;
  margin-bottom: 80px;
  margin-top: 40px;
  max-width: 180px;
  &:hover{
    background-color: $teal;
    color: white;
  }
}


.NewsArticles{
  max-width: 1170px;
  margin: auto;
  display: flex;
  flex: 0 0 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 80px 15px!important;
  padding-top: 120px!important;

  h2{
    width: 100%;
  }
  article{
    flex: 0 0 100%;
    max-width: calc(50% - 15px);
    @media only screen and (max-width: 576px) {
      max-width: 100%;
    }
    position: relative;
    overflow: hidden;
    border-radius: 32px;
    background: linear-gradient(to top, black 50%, white 50%);
    margin-bottom: 15px;
    border: 5px solid black;

    .entry-summary{
      padding: 40px 25px;
      background-color: $black;
    }
    .imgbox{
        max-height: 350px;
        height: auto;
        width: 100%;
        min-height: 350px;
        object-fit: contain;
        object-position: center;
    }
    img{
      max-height: 350px;
      min-height: 350px;
      height: auto;
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
    h3, p{
      color: white;
    }

    /*.button{
      color: black;
      &::after{
        filter: invert(1);
      }
    }*/
  }
}
.navigation{
  ul{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    flex: 0 0 100%;
    justify-content: center;
  }
}


#TabbedSlider2, #TabbedSlider1{
  width: 100%;
  max-width: calc(100% - 40px);
}


.TabbedBoxes{
  padding-top: 80px;
  padding-bottom: 80px;
  min-height: 900px;
  .row{
    //max-width: 1400px;
    max-width: 1350px;
  }
  .slick-track{
    min-width: 100%;
  }
  @media only screen and (max-width: 768px) {
    .slick-track {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-wrap: wrap;
        width: 100% !important;
        flex: 0 0 100%;
        max-width: 1350px;
        overflow: visible;
        min-width: 100%;
    }
      .slick-list {
        position: relative;
        display: block;
        overflow: visible!important;
        margin: 0;
        padding: 0;
    }
  }

  .button:after, input[type=submit]:after{
    margin-left: 0px;
  }
  .slick-prev{
    left: 15px;
    &:after{
      transform: rotate(180deg)!important;
    }
    @media only screen and (max-width: 768px) {
      display: none!important;
    }
  }
  .slick-next{
    right: 15px;
    @media only screen and (max-width: 768px) {
      display: none!important;
    }
  }
  .slick-disabled{
    opacity: 0.5!important;
  }
  .button.yellow {
      position: absolute;
      min-width: 20px;
      border: 0px;
      opacity: 1;
      bottom: -80px;
      z-index: 999999;
  }
  .nav-tabs{
    display: flex;
    flex: 0 0 100%;
    justify-content: center;
    max-width: 1400px;
    padding: 0px 15px;
    flex-wrap: wrap;
    position: relative;
    border: 0px!important;
    //border-bottom: 1px solid $gold!important;
    .nav-item{
      margin: 0px;
      position: relative;
      .nav-link{
        background-color: $black;
        color: $white;
        padding: 15px 40px;
        font-size: 1.8rem;
        font-weight: bold;
        cursor: pointer;
        text-align: center;
        display: flex;
        position: relative;
        justify-content: center;
        border: 2px solid white;
      }
      .nav-link.active{
        border: 2px solid $gold;
        font-weight: bold;

        color: $gold;
        &:after{
          content: "";
          //background-image: url(img/newimgs/bluechev.png);
          background-repeat: no-repeat;
          display: inline-block;
          width: 19px;
          height: 16px;
          background-size: cover;
          align-self: center;
          position: absolute;
          align-self: center;
          bottom: -15px;
        }

      }
    }
    .nav-link.active{
      //background-color: $teal;
      //color: white;
    }
  }
  li::before{
    display: none!important;
  }
  .tab-content{
    display: block;
    width: 100%;
    max-width: 100%;
    max-width: 1400px;
    padding: 0px 15px;
    position: relative; 
    margin-bottom: 40px;
    .tab-pane {

        opacity: 0;
        position: absolute;
        top: 0;
        left: 15px;
        width: 100%;
        display: none;
        max-width: calc(100% - 40px);
        @media only screen and (min-width: 768px) {
          display: block!important;
        }
        @media only screen and (max-width: 768px) {
          position: relative;
        }
    }
    .tab-pane.active{
      display: block;
      width: 100%;
      max-width: 100%;
      opacity: 1;
      display: block;
      z-index: 9999;
    }
    .infobox{
      display: block;
      padding: 20px 2px;
      position: relative; 
      margin: 10px;
      text-decoration: none;
      text-align: center;
      min-height: 225px;
      flex-direction: column;
      max-width: calc(33% - 30px);
      min-width: calc(33% - 30px);
      //display: flex;
      //flex-wrap: wrap;
      //align-items: center;
      //justify-content: flex-start;

      img{
        max-width: 300px;
        width: 100%;
        margin: auto;
        margin-bottom: 30px;
        margin-top: 0px;
      }
      @media only screen and (max-width: 991px) {
        min-width: 160px;
        width: 50%!important;
        max-width: calc(50% - 20px)!important;
      }
      @media only screen and (max-width: 768px) {
        width: 100%!important;
        max-width: 100%!important;
      }


      i{
        color: $teal;
        font-size: 54px;
        margin: auto;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      h5{
        font-size: 44px;
        text-align: center;
        margin-bottom: 20px;
      }
      p{
        font-size: 1.8rem;
        //font-family: "Oxanium", "Rajdhani", sans-serif;
        color: #000000;
        line-height: 1.5;
        margin-bottom: 2rem;
        width: 100%;
      }
    }

  }
}







.HoverBlockColumns{
  position: relative;
  display: flex;
  flex: 0 0 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  max-width: 100%;
  .row{
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    max-width: 100%;

  .bgoverlay{
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 98;
    background-color: #083357;
    opacity: 1;
    top: 0px;
    left: 0px;
    height: 80vh;
    display: none;
  }
  .opacityimage{
    position: absolute;
    width: 100%;
    height: auto;
    z-index: 99;
    bottom: 75%;
    left: 0px;
    @media only screen and (max-width: 768px) {
      //background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 50%, rgba(255, 255, 255, 0) 100%);
    }
  }
  .arrowdiv{
    width: 80px;
    text-align: center;
    @media only screen and (max-width: 768px) {
      align-self: center;
    }
    img{
      width: 40px;
    }
  }
  .hoverblock{
    height: 80vh;
    object-fit: cover;
    background-size: cover;
    position: relative;
    z-index: 97;
    object-position: center;
    display: flex;
    flex: 0 0 100%;
    flex-wrap: wrap;
    position: relative;
    padding: 0px;
    overflow: hidden;

    h5{
      font-size: 3rem;
      font-family: $title;
      letter-spacing: 4px;
      line-height: 40px;
      margin-bottom: 25px;
      color: white;
      text-transform: uppercase;
      max-width: 560px;
      text-align: center;

      @media only screen and (max-width: 768px) {
        margin-bottom: 0px;
      }
    }

    p{
      font-size: 1.6rem;
      font-family: $title;
      line-height: 30px;
      color: white;
      text-align: center;
      max-width: 560px;
      margin: auto;
      margin-bottom: 25px;
      //border-left: 7px solid $teal;
      padding-left: 30px;
      position: relative;
    }


    .button{
      margin-top: 25px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    .defaultview{
      -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
        -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
      position: absolute;
      top: 0px;
      z-index: 99;
      align-self: flex-end;
      display: flex;
      flex: 0 0 100%;
      justify-content: center;
      flex-wrap: nowrap;
      position: relative;
      max-width: 100%;
      padding: 40px;
      min-height: 165px;
      opacity: 0.9;
      background-color: #083357;
      @media only screen and (max-width: 1280px) {
        min-height: 175px;
      }
      @media only screen and (max-width: 991px) {
        min-height: auto;
      }
      h5{
        width: 100%;
        max-width: fit-content;
        margin-bottom: 0px;
        align-self: center;
        font-size: clamp(2rem, 1.5vw, 3rem);
        @media only screen and (max-width: 991px) {
          font-size: clamp(2.2rem, 2vw, 3rem);
        }
      }
      .arrowdiv{
        align-self: center;
      }
    }
    .hovercontent{
      display: none;
      -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
        -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
      position: absolute;
      top: 0px;
      z-index: 99;
      align-self: center;
      flex: 0 0 100%;
      justify-content: center;
      flex-wrap: wrap;
      position: relative;
      max-width: 100%;
      padding: 40px;
      flex-direction: column;
      *{
        align-self: center;
      }

    }
  }

  .hoverblock:hover {
    .bgoverlay{
      display: block;
    }
    .opacityimage{
      display: block;
    }
    .hovercontent{
      display: flex;
      -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
        -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;

            h5{
            max-width: 450px;
            margin-bottom: 25px;
            }
            hr{
              margin-bottom: 25px;
            }
            p{
            margin-bottom: 40px;
            }
    }
    .defaultview{
      display: none;
      -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
        -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
    }
  }
  
}

}



@keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}





#IndustrySlider {
  .sectortitle {
    font-family: $title;
    color: $black;
    font-size: 4.5rem;
    font-weight: 400;
    margin-top: 40px;
    margin-bottom: 40px;
    line-height: 70px;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
  }
  .slidericons {
    height: 95px;
  }
  .tagline {
    font-family: $title;
    text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: 4px;
    color: $teal;
    margin-bottom: 20px;
    display: block;
    text-align: center;
  }

  .viewallsectors {
    display: flex;
    justify-content: center;
    flex: 0 0 100%;
    max-width: 100%;
    height: 103px;
    background-color: $teal;
    color: white;
    font-size: 2rem;
    font-family: $title;
    text-decoration: none;
    padding: 40px;
  }
  .row {
    justify-content: center;
    .blobbreak {
      justify-content: center;
    }
  }
  .slickslidercontainer {
    .slickslider {
      .slick-arrow {
        position: absolute;
        top: 50%;
        z-index: 1;
        width: 260px;
        height: 66px;
        transform: translateY(-50%);
        letter-spacing: 3px;
        color: #1c1d1e;
        align-self: center;
        text-transform: uppercase;
        background-color: $teal;
        padding: 20px 25px;
        font-family: $title;
        min-width: 267px;
        text-align: center;
        display: inline-block;
        border-radius: 40px;
        font-size: 1.6rem;
        -webkit-transition: all 0.75s ease-in-out;
        -moz-transition: all 0.75s ease-in-out;
        -o-transition: all 0.75s ease-in-out;
        transition: all 0.75s ease-in-out;
        line-height: 0.8;
        border: 0px;

        &:hover,
        &:focus {
          //border-color: #F08A5D;
        }

        &:active {
          transform: translateY(-50%) scale(0.9);
        }
      }

      .slick-arrow:after {
        content: " ";
        display: inline-block;
        position: relative;
        //background-image: url(img/ionic-ios-arrow-round-back.svg);
        width: 24px;
        height: 16px;
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: 20px;
        filter: invert(11) sepia(0) saturate(0) hue-rotate(190deg);
        top: 3px;
      }

      .slick-prev {
        left: -50px;
        text-align: start;
        direction: rtl;
        @media only screen and (max-width: $breakpoint-xxl) {
          left: -100px;
        }

        &:after {
          transform: rotate(180deg);
          margin-right: 20px;
        }
      }

      .slick-next {
        right: -50px;
        text-align: left;
        @media only screen and (max-width: $breakpoint-xxl) {
          right: -100px;
        }
      }
    }

    .slickslider {
      position: relative;
      height: 85vh;

      .slidebg {
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        position: absolute;
        z-index: 97;
        height: 85vh;
        object-position: center;
      }
      .slidebgoverlay {
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        position: absolute;
        z-index: 98;
        background-color: #1c1d1e;
        opacity: 0.8;
        top: 0px;
        height: 85vh;
      }

      .slick-slide {
        position: relative;
        height: 85vh;
        .slidercontent {
          position: relative;
          z-index: 99;
          display: flex;
          justify-content: space-between;
          flex: 0 0 100%;
          max-width: 100%;
          height: 85vh;
          padding: 40px;
          flex-wrap: wrap;
          flex-direction: column;
          padding-top: 55px;
          text-decoration: none;
          .slideicon {
            width: 100%;
            max-width: 70px;
            //filter: invert(11) sepia(0) saturate(0) hue-rotate(190deg);
            margin: auto;
            display: block;
            margin-bottom: 40px;
          }
          h5 {
            font-family: $title;
            font-size: 2.2rem;
            color: white;
            letter-spacing: 1px;
            line-height: 42px;
            text-transform: uppercase;
            width: 100%;
            text-align: center;
            letter-spacing: 4px;
          }

          .slidercounter {
            .rownum {
              //font-family: $bold;
              color: $white;
              font-size: 5.8rem;
            }
            .slidecount {
              font-family: $title;
              font-size: 2.2rem;
              color: $white;
              position: relative;
              top: -45px;
              left: 10px;
            }
          }
        }
      }

      .slick-slide:hover,
      .slick-slide:active,
      .slick-slide:focus {
        h5,
        .slidecount,
        .rownum {
          color: $teal !important;
        }
        .slidercontent {
          .slideicon {
            //filter: invert(1) sepia(1) saturate(90) hue-rotate(318deg)!important;
            //filter: invert(75%) sepia(44%) saturate(2560%) hue-rotate(356deg) brightness(96%) contrast(87%) !important;
          }
        }
      }
    }
  }
}



#CarouselSlider{
  padding: 60px 15px;

  .slider-for{
    max-width: 760px;
    margin: auto;
    margin-bottom: 40px;
    border: 5px solid white;
    outline: 1.5px solid black;
    -webkit-box-shadow: 0px 8px 10px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 8px 10px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 8px 10px 2px rgba(0, 0, 0, 0.3);
    .slick-slide {
        min-height: 500px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        .slidecontent{
          position: absolute;
          //width: 99.1vw;
          //width: 100vw;
          width: 100%;
          bottom: 0px;
          background-color: #00539be0;
      
        }
        .slideinfo{
          display: flex;
          flex: 0 0 100%;
          max-width: 1240px;
          margin: auto;
          padding: 20px 0px;
          justify-content: space-between;
          flex-wrap: wrap;
          position: relative;
          @media only screen and (max-width: $breakpoint-xxl) {
            padding: 20px 20px;
            padding-bottom: 60px;
          }
          h4{
            margin-left: 15px;
            margin-bottom: 0px;
            font-size: 3.2rem;
            font-weight: bolder;
            width: 100%;
            color: white;
            text-transform: uppercase;
          }

        }
    }
  }
  .slider-nav{
    max-width: calc(100% - 150px);
    margin: auto;
      .slick-slide {
          min-height: 200px;
          margin: 10px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          border: 5px solid white;
          outline: 1.5px solid black;
      }
      .slick-current{
        outline: 0px solid $teal;
        border: 5.5px solid $teal;
      }
      .button{
        position: absolute;
        min-width: 20px;
        border: 0px;
        opacity: 1;
        bottom: 75px;
        z-index: 99;
      }
      .button:after, input[type=submit]:after{
        margin-left: 0px;
      }
      .slick-prev{
        left: -75px;
        &:after{
          transform: rotate(180deg);
        }
      }
      .slick-next{
        right: -75px;
      }
      .slick-disabled{
        opacity: 0.5;
      }
  }
}



.BlockColumns.imggallery{
  .row {
    padding: 40px 15px;
  }
  a:not(.button){
    border: 0px!important;
  }
  a{
    max-height: 300px;
    position: relative;
    overflow: hidden;
    display: inline-block;
    padding: 5px;
    height: 100%;
    img{
      margin-bottom: 40px;
      max-height: 300px;
      object-fit: cover;
      object-position: center;
      width: 100%;
      padding: 5px;
    }
  }
}

