@use "variables" as *;

header {
  //max-height: 109px;
  //background-color: transparent;
  background: #1c1c1c61;
  flex: 0 0 100%;
  display: flex !important;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 99999;
  //position: absolute;
  position: fixed;
  width: 100%;
  //height: 109px;

  #mega-menu-wrap-primary-menu #mega-menu-primary-menu {
    text-align: left;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }

  #bottombar {
    flex: 0 0 100%;
    max-width: 100%;
    background-color: transparent;
    flex: 0 0 100%;
    display: flex !important;
    max-width: 100%;
    position: relative;
    .row{
      flex-wrap: nowrap;

      @media only screen and (max-width: 480px) {
        flex-wrap: wrap;

      }
      
    }
    .menucontainer{
      flex-wrap: nowrap;
      display: flex;
      align-self: center;
      justify-content: space-between;
      position: relative;
      @media only screen and (max-width: 1300px) {
        margin-right: 100px;
      }
      @media only screen and (max-width: 550px) {
        width: 50px;
      }
      #mega-menu-wrap-primary-menu .mega-menu-toggle{
      background: transparent!important;
      }
    }
    .headerbutton{
      color: white;
      font-family: $Rajdhani; 
      font-weight: bold;
      font-size: 1.8rem;
      border-bottom: 1.5px solid $teal;
      display: block;
      position: relative;
      align-self: flex-end;
      line-height: 2;
      @media only screen and (max-width: 550px) {
        display: none;
      }
    }

    .logo-text {
      display: flex;
      align-self: flex-end;
      .logo {
        display: inline-flex;
        margin-top: 15px;
        margin-bottom: 15px;
        align-self: flex-end;
      }
    }
    #logo {
      display: flex;
      flex: 0 0 100%;
      max-width: 230px;
      flex-wrap: nowrap;
      img {
        align-self: center;
      }
      .full-text {
        margin-left: 15px;
        align-self: center;
        font-family: $title;
        color: $black;
        font-size: 2rem;
        font-weight: 600;
      }
    }

    /*nav {
      width: 100%;
      //display: none;
      max-width: 55px;
      bottom: -5px;
      position: relative;

      ul#mega-menu-menu-1 {
        width: 100%;
        display: flex;
        //max-width: 675px;
        //justify-content: space-between;

        li:last-child {
          a {
            padding-right: 0px !important;
          }
        }
      }
    }*/
  }
  .row {
    max-width: 1170px;
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
    flex: 0 0 100%;
    display: flex !important;
    justify-content: space-between;
    align-self: center;
  }
}

@media only screen and (min-width: 1050px) {
  header{
    #mega-menu-wrap-menu-1
    #mega-menu-menu-1
    > li.mega-menu-item.mega-current-menu-item
    > a.mega-menu-link,
  #mega-menu-wrap-menu-1
    #mega-menu-menu-1
    > li.mega-menu-item.mega-current-menu-ancestor
    > a.mega-menu-link,
  #mega-menu-wrap-menu-1
    #mega-menu-menu-1
    > li.mega-menu-item.mega-current-page-ancestor
    > a.mega-menu-link {
    color: $darkblue !important;
  }
  }

}


#scrolltop {
  width: 56px;
  height: 56px;
  position: fixed;
  right: 20px;
  bottom: 0px;
  transform: translateY(150vh);
  transition: transform 500ms ease-in-out;
  z-index: 9999;
  //padding: 7px 10px;
}
#scrolltop.scrolled {
  //bottom: 20px;
  transform: translateY(-2vh);
}

.menu-item-has-children{
  position: relative;
  &::after{
    content: "+";
    display: inline-block;
    position: absolute;
    top: 25px;
    font-size: 24px;
    right: -10px;
    color: white;
    transform: skew(16deg);
    @media only screen and (max-width: 576px) {
      transform: skew(0deg) translateX(0%);
    }
  }
}




#menustate.active{
  overflow: hidden;
  position: relative;
  height: 100vh;
}
.navTrigger {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 99999;
  width: 36px;
  height: 36px;
  background: none;
  border: 0;
  outline: none;
  border: none;
  cursor: pointer;
  -webkit-appearence: none;
  -webkit-tap-highlight-color: transparent;

  @media only screen and (max-width: 550px) {
    top: -5px;
  }
  &:focus {
    outline: none;
  }
  svg {
    width: 64px;
    height: 48px;
    top: -6px;
    left: -14px;
    stroke: $white;
    stroke-width: 4px;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    display: block;
    position: absolute;
    path {
      transition: stroke-dasharray var(--duration, .85s) var(--easing, ease) var(--delay, 0s), stroke-dashoffset var(--duration, .85s) var(--easing, ease) var(--delay, 0s);
      stroke-dasharray: var(--array-1, 26px) var(--array-2, 100px);
      stroke-dashoffset: var(--offset, 126px);
      transform: translateZ(0);
      &:nth-child(2) {
        --duration: .7s;
        --easing: ease-in;
        --offset: 100px;
        --array-2: 74px;
      }
      &:nth-child(3) {
        --offset: 133px;
        --array-2: 107px;
      }
    }
  }
  &.active {
    svg {
      stroke: $white;
      path {
        --offset: 57px;
        &:nth-child(1),
        &:nth-child(3) {
          --delay: .15s;
          --easing: cubic-bezier(.2, .4, .2, 1.1);
        }
        &:nth-child(2) {
          --duration: .4s;
          --offset: 2px;
          --array-1: 1px;
        }
        &:nth-child(3) {
          --offset: 58px;
        }
      }
    }
  }
}

.navMenu {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  background: transparent;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: all .375s;
  height: 100vh;
  &.active {
    opacity: 1;
    z-index: 9999;
    visibility: visible;
    &::before {
      transform: skew(-14deg) translateX(0);
      @media only screen and (max-width: 576px) {
        transform: skew(0deg) translateX(0);
      }
    }
    li {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -15px;
    //background: $teal;
    background: #8d6c14fa;
    transform-origin: 0 0;
    transform: skew(-14deg) translateX(-120%);
    transition: all .275s .1s;
    @media only screen and (max-width: 576px) {
      transform: skew(0deg) translateX(0%);
    }
  }
  ul {
    list-style: none;
    padding: 0;
    display: inline-flex;
    flex-direction: column;
    height: 100vh; /* Should be 100%, but we have a notice message :D */
    align-items: flex-end;
    justify-content: center;
    transform: translateX(-18%) skew(-16deg);
    position: relative;

    @media only screen and (max-width: 768px) {
     transform: translateX(-100%) skew(-16deg);
    }
    @media only screen and (max-width: 576px) {
      transform: skew(0deg) translateX(-0%);
      padding: 0;
      display: inline-flex;
      flex-direction: column;
      height: 100vh;
      align-items: flex-start;
      width: 100%;
      justify-content: center;
    }
    li {
      display: inline-flex;
      margin: .5rem 0;
      //text-align: right;
      opacity: 0;
      transform: translateY(-10px);
      justify-content: center;
      .sub-menu{
        display: none;
        position: absolute;
        right: -60px;
        left: auto;
        transform: translateX(-500px);
        opacity: 0;
        transition: transform .3s, opacity .5s;
        height: auto;
        z-index: 99;
        align-self: center;

        @media only screen and (max-width: 576px) {
          right: auto;
          left: auto;
          transition: transform 0.3s, opacity 0.5s;
          height: auto;
          z-index: 99;
          align-self: center;
          width: 100%;
          transform: translateX(75px);
        }
        li{
          min-width: 200px;
          a{
            text-align: center;
            @media only screen and (max-width: 576px) {
            padding: 15px 20px;
            }
          }
        }

      } 
      a {
        position: relative;
        left: 0;
        display: block;
        padding: 25px 20px;
        color: #FFFFFF;
        font-size: 1.8rem;
        font-family: $subtitle;
        text-decoration: none;
        transform: skew(16deg);
        transition: .25s;
        font-weight: bold;
        text-align: center;
        @media only screen and (max-width: 576px) {
          transform: skew(0deg);
        }
        &:hover {
          left: 10px;
        }
      }
      &:nth-child(1) {
        transition: all 275ms 275ms;
      }
      &:nth-child(2) {
        transition: all 275ms 325ms;
      }
      &:nth-child(3) {
        transition: all 275ms 375ms;
      }
      &:nth-child(4) {
        transition: all 275ms 425ms;
      }
      &:nth-child(5) {
        transition: all 275ms 475ms;
      }
    }
    li.show-children{
      .sub-menu{
        display: inline-block;
        opacity: 1;
        transform: translateX(135px);
        @media only screen and (max-width: 576px) {
          transform: translateX(135px);
        }
        li{
          background-color: white;
          color: $black;
          a{
            background-color: white;
            color: black;
          }
        }

      }
    }
    li:hover,
     li:focus{
      .sub-menu{
        display: block!important;
        position: absolute;
        opacity: 1; 
        transform: translateX(135px);
        li{
          background-color: white;
          color: $black;
          a{
            color: black;
          }
        }
      }
    }
  }
}


li.menu-item-has-children > ul {
  display: none;
}

li.menu-item-has-children.show-children > ul {
  display: block;
}
nav{
  .navsocial{
    align-self: flex-start;
    margin-left: -15px;
    position: relative;
    margin-top: 60px;
    a{
      margin: 15px;
      i{
        color: #756f6f;
        font-size: 24px;
      }
    }
    a:hover{
      i{
        color: $teal;
      }
    }
  }
  .menu__item.menu__item--1{
    .subtitle{
      top: 100px;
      left: -60px;
      transform: rotate(-90deg);
    }

  }
  @media only screen and (max-width: 650px) {
    .menu__item.menu__item--3{
      .subtitle{
        top: 100px;
        left: -60px;
        transform: rotate(-90deg);
      }
  
    }
  }
  .sidemenu__item:hover, .sidemenu__item:focus{
    color: $teal;
  }
  .wp-block-image img{
    width: 160px!important;
  }
  .menu__item.menu__item--4{

    .menu__item-inner{
      align-items: center; 
      justify-content: flex-end;
      gap: 20px;
      padding: 40px;
      img{
        margin-right: 10px;
        
      }
      .info{
        align-self: flex-start;
        font-size: 1.6rem;
        text-align: left;
      }
    }
  }
  .subtitle {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    flex: 0 0 100%;
    font-size: 24px;
    position: absolute;
    top: 40px;
    left: 15px;
    p{
      color: #756f6f;
      font-size: 2rem;
      top: -5px;
      position: relative;
      left: -10px;
    }
  }
  .mainmenu__item, .menu__item-hoverlink{
    font-family: $Bebas;
  }
  .sidemenu__item{
    font-family: $Oxanium;
  }
}
